import img1 from '../images/mark/mk-slider-333.png';
import img2 from '../images/mark/mk-header-777.png';
import img3 from '../images/mark/mk-slider-5.jpg';
import img4 from '../images/mark/mk-slider-1.jpg';



const dataBanner = [
    {
        id: 1,
        classAction: 'flat-slider flex',
        title: 'Sho',
        title2: 't',
        title3: 'Agent',
        title4: 'Video Producer-Customer Bridge',
        subtitle: 'For those seeking top-tier video services, Initiate your video requests on Shot Agent today! And for talented video producers, join us to showcase your skills!',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
]

export default dataBanner;