import axios from 'axios';

import { API_URL } from './constants';

export const fetchFreelancerData = async (email) => {
    try {
        const response = await axios.get(`${API_URL}/freelancer`, {
            params: {
                email: email
            }
        });
        console.log('Fetched freelancer data from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
