// frontend/src/api/clientProfileApi.js
import axios from 'axios';

import { API_URL } from './constants';

// Function to update CoCampaignResults data
export const updateClientProfile = (data, dispatchFirstName, dispatchLastName, dispatchCompanyName,
    dispatchPosition, dispatchCompanyOwner, dispatchPhone, dispatchVatid, dispatchTimeZone, dispatchAddress, dispatchPhoto) => {
    // Dispatch actions to update the state based on the provided data
    dispatchFirstName(updateFirstNameAction(data));
    dispatchLastName(updateLastNameAction(data));
    dispatchCompanyName(updateCompanyNameAction(data));
    dispatchPosition(updatePositionAction(data));
    dispatchCompanyOwner(updateCompanyOwnerAction(data));
    dispatchPhone(updatePhoneAction(data));
    dispatchVatid(updateVatidAction(data));
    dispatchTimeZone(updateTimeZoneAction(data));
    dispatchAddress(updateAddressAction(data));
    dispatchPhoto(updatePhotoAction(data))

};



// Example action creators for updating state
export const updateFirstNameAction = (data) => ({
    type: 'UPDATE_FIRST_NAME',
    payload: data[0].first_name, // Check the correct property name
});

export const updateLastNameAction = (data) => ({
    type: 'UPDATE_LAST_NAME',
    payload: data[0].last_name, // Check the correct property name
});


export const updateCompanyNameAction = (data) => ({
    type: 'UPDATE_COMPANY_NAME',
    payload: data[0].company_name, // Check the correct property name
});

export const updatePositionAction = (data) => ({
    type: 'UPDATE_POSITION',
    payload: data[0].position, // Check the correct property name
});

export const updateCompanyOwnerAction = (data) => ({
    type: 'UPDATE_COMPANY_OWNER',
    payload: data[0].company_owner, // Check the correct property name
});

export const updatePhoneAction = (data) => ({
    type: 'UPDATE_PHONE',
    payload: data[0].phone, // Check the correct property name
});

export const updateVatidAction = (data) => ({
    type: 'UPDATE_VATID',
    payload: data[0].vatid, // Check the correct property name
});

export const updateTimeZoneAction = (data) => ({
    type: 'UPDATE_TIME_ZONE',
    payload: data[0].time_zone, // Check the correct property name
});

export const updateAddressAction = (data) => ({
    type: 'UPDATE_ADDRESS',
    payload: data[0].address, // Check the correct property name
});

export const updatePhotoAction = (data) => ({
    type: 'UPDATE_CLIENT_PHOTO_LINK',
    payload: data[0].client_photo_link, // Check the correct property name
});


export const fetchClientProfile = async (userEmail) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', userEmail);
        const headers = { 'user-email': userEmail };

        const response = await axios.get(`${API_URL}/editProfile-client`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching Client Profile:', error);
        throw error;
    }
};
/*
// Function to initialize CoCampaignResults data
export const getClientProfile = async (userEmail, dispatchFirstName, dispatchLastName, dispatchCompanyName,
    dispatchPosition, dispatchCompanyOwner, dispatchPhone, dispatchVatid, dispatchTimeZone, dispatchAddress, dispatchPhoto) => {
    try {
        const initialData = await fetchClientProfile(userEmail);

        console.log('Response from server:', initialData);

        // Call the function to handle the update
        updateClientProfile(initialData, dispatchFirstName, dispatchLastName, dispatchCompanyName,
            dispatchPosition, dispatchCompanyOwner, dispatchPhone, dispatchVatid, dispatchTimeZone, dispatchAddress, dispatchPhoto);
    } catch (error) {
        console.error('Error to get Client Profile:', error);
    }
};
*/
export const uploadProfilePhoto = async (email, formData) => {
    try {
      const response = await axios.post(`${API_URL}/editProfile-client/upload-photo`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading profile photo:', error);
      throw error;
    }
  };
  
  export const fetchProfilePhoto = async (email) => {
    try {
      const response = await axios.get(`${API_URL}/editProfile-client/photo`, {
        params: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile photo:', error);
      throw error;
    }
  };
  
  export const deleteProfilePhoto = async (email) => {
    try {
      const response = await axios.delete(`${API_URL}/editProfile-client/delete-photo`, {
        data: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting profile photo:', error);
      throw error;
    }
  };
  

//new get profile data
// Function to get the client profile by email
export const getClientProfile = async (email) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', email);
        const headers = { 'user-email': email };

        const response = await axios.get(`${API_URL}/editProfile-client`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching Client Profile:', error);
        throw error;
    }
};