import React, { useState } from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Blog from '../components/blog/blog-v1';
import dataBlog from '../assets/fake-data/data-blog';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'

function Popup(props) {
    return (props.trigger) ? (
        <div className="popup-video">
            <div className="overlay" onClick={() => props.setTrigger(false)}></div>
            <div className='popup-inner'>
                <div className="popup-inner-content">
                    <div className="mfp-iframe-scaler">
                        <ReactPlayer url='https://www.youtube.com/watch?v=i7EMACWuErA'
                            playing={false}
                            controls={false} className="popup-youtube">
                            <span className="icon-play"></span>
                        </ReactPlayer>
                        <button className='close-btn' onClick={() => props.setTrigger(false)}><i className="far fa-times"></i></button>
                        {props.children}
                    </div>

                </div>
            </div>
        </div>
    ) : "";
}


function About(props) {
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "Great Strategy",
            },
            {
                id: 2,
                title: "Amazing Ideas",
            },
            {
                id: 3,
                title: "Creative Deals",
            },
            {
                id: 4,
                title: "Quick Support",
            },
        ]
    )
    const [buttonPopup, setButtonPopup] = useState(false);
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='About Us' />
            <div className="tf-section flat-about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="wrap-img">
                                <div className="media">
                                    <img src={require('../assets/images/box-item/team.jpg')} alt="Nufti" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="post">
                                <h2 className="title-about text-t">ShotAgent, Where Video Comes to Life.</h2>
                                <div className="title-text fs-18 fw-4 text-color-3">At shotAgent, our mission is to establish a robust infrastructure fostering international collaboration between videographers and consumers seeking tailor-made video content.</div>
                                <p className="text-1"> We envision a future where live imaging, amidst the proliferation of computer-generated graphics, becomes a premium commodity. As transportation costs rise and specialist availability diminishes, we strive to position shotAgent as a global platform aimed at democratizing access to authentic, high-quality imagery. By harnessing technology, we aim to drive down production costs and empower individuals and businesses alike to access the real-life visuals they desire. Join us in shaping the future of visual storytelling.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flat-video">

            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup} className="popup-youtube">
                <span className="icon-play">abc</span>
            </Popup>
            {<Blog classection='tf-section flat-blog home3 page' data={dataBlog} />}

            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default About;