import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { fetchProposalById, AcceptFreelancer } from '../../../api/projectProposalApi';

import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/author-profile.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3';
import { SHOTAGENT_WALLET_ADDRESS } from '../../../api/constants';
import axios from 'axios'; // Import axios for making API requests


const ProposalProfile = (props) => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const data = props.data;
    const [modalShow, setModalShow] = useState(false);



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const proposalId = searchParams.get('id');

    const [proposal, setProposal] = useState(null);

    const [freelancerCost, setFreelancerCost] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState("");
    const [conversionRate, setConversionRate] = useState(0); // State to hold conversion rate

    useEffect(() => {
        const fetchProposal = async () => {
            try {
                const proposalData = await fetchProposalById(proposalId);
                setProposal(proposalData);
                //web3 & Metamask
                if (window.ethereum) {
                    // MetaMask is installed
                    try {
                        //const web3Instance = new Web3(rpcEndpoint); // Instantiate Web3 with custom RPC endpoint
                        const web3Instance = new Web3(window.ethereum); //test code
                        await window.ethereum.enable();
                        setWeb3(web3Instance);
                        console.log('inside initialize, web3Instance:', web3Instance);
                        const accounts = await web3Instance.eth.getAccounts();
                        console.log('inside initialize, accounts:', accounts);
                        //get conversion rate of EUR to ETH
                       
                        if (accounts.length > 0) {
                            console.log('inside initialize, accounts[0]:', accounts[0]);
                            setAccount(accounts[0]);

                        } else {
                            console.error('No accounts found.');
                        }
                    } catch (error) {
                        console.error('Error initializing web3:', error);
                        console.error('Error fetching conversion rate:', error);
                    }
                } else {
                    console.error('MetaMask not detected.');
                }

            } catch (error) {
                console.error('Error fetching Proposal details:', error);
            }
        };
        fetchProposal();
    }, [proposalId]);


    console.log('Proposal by id in front:', proposal);


    //proposal submission

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            // Submit the proposal to the backend
            await AcceptFreelancer({
                projectId: proposal[0].project_id,
                freelancerEmail: proposal[0].freelancer_email,
                freelancerFirstName: proposal[0].freelancer_first_name,
                freelancerLastName: proposal[0].freelancer_last_name,
                projectPrice: proposal[0].freelancer_cost,
                status: 'doing',
            });

            setRequestSuccess(true); // Set the registration success state to true

            // Show success notification
            toast.success('Congratulation, Start Your Project!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = `/client-project-management?id=${proposal[0].project_id}`;
            }, 3000);

        } catch (error) {
            console.error('Error Starting Cooperation:', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
            } else {
                toast.error('Error Starting Cooperation!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };


    //crypto payment
    //payment funtion
    const payFreelancerProposal = async () => {
        try {
            if (!web3) {
                console.error("Web3 is not initialized.");
                return;
            }

            // Force the amount to be a number and ensure it is in the correct format
            //const amountInEth = 500; // Set your test amount
            const clientAddress = account;
            const amountInEur = proposal[0]?.freelancer_cost || 0;
            //conversion to ETH
            const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=eur');
            const rate = response.data.ethereum.eur; // Extract the conversion rate
            setConversionRate(parseFloat(rate));
            console.log('Current EUR to ETH conversion rate:', rate);
            const amountInEth = parseFloat(amountInEur/ rate); // Calculate ETH based on real-time rate

            // Convert ETH to Wei using Web3.js and force it as a string
            const amountInWei = web3.utils.toWei(amountInEth.toString(), 'ether');
            const gasPrice = await web3.eth.getGasPrice();

            console.log('amountInEur:', amountInEur);
            console.log('amountInEth:', amountInEth);
            console.log('ETH amount (for reference):', amountInEth); // Should be 1 for testing
            console.log('Converted to Wei (for reference):', amountInWei); // Should be '1000000000000000000'

            const transactionParameters = {
                to: SHOTAGENT_WALLET_ADDRESS,
                from: clientAddress,
                value: web3.utils.toHex(amountInWei), // Ensure it's a hex string
                gas: gasPrice, // Optional: Consider setting this dynamically
            };

            console.log('Transaction Parameters:', transactionParameters);

            // Send the transaction via MetaMask
            const txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });

            console.log(`Transaction successful with hash: ${txHash}`);
            alert("Payment successful! Proceed to project management.");

             // Proceed to handle accept only if payment was successful
             handleAccept();
            
        } catch (error) {
            console.error("Error during payment:", error);
            alert("Payment failed. Please try again.");
        }
    };

    if (!proposal) {
        return <div>Loading...</div>;
    }




    return (
        <Fragment>
            <div className="tf-section flat-author-profile flat-explore flat-auctions " style={{ marginBottom: '0em' }}>
                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex">
                            <div className="feature-profile flex">
                                <div className="img-box relative">
                                    <img className="avatar" src={img1} alt="" />
                                </div>
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{proposal[0].freelancer_first_name} {proposal[0].freelancer_last_name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tf-section flat-about" style={{ marginTop: '0em' }}>
                    <div className="container">
                        <div className="post">
                            <h2 className="title-about text-t">Detail of Proposal</h2>
                            <p className="text-1">{proposal[0].proposal_description}</p>

                            <div className="box">
                                <div className="title-text fs-18 fw-4 text-color-3">My suggested price for this project is equal to: {proposal[0].freelancer_cost} EUR </div>
                                <button
                                    onClick={payFreelancerProposal}
                                > Accept, Pay and start cooperation</button>
                            </div>
                        </div>
                        <div className="back-arrow"
                            style={{ cursor: "pointer", fontSize: "1.5em" }}
                            onClick={() => navigate(-1)}
                        >
                            &#8592; Back
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>

    );
};


export default ProposalProfile;
