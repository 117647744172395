import React, { useState, useEffect } from 'react';
import '../scss/components/section.scss';
import { Link } from "react-router-dom";
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import img1 from '.././assets/images/box-item/avatar.png';
// Backend Connection
import { editClient } from "../api/auth";

// Showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getClientProfile } from '../api/clientProfileApi';

// For photo
import { uploadProfilePhoto, fetchProfilePhoto, deleteProfilePhoto } from '../api/clientProfileApi';
import { fileBaseUrl } from '../api/constants.js';

function EditProfileClient() {
    const [profilePhoto, setProfilePhoto] = useState(img1);
    const [userData, setUserData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        company_name: '',
        position: '',
        company_owner: '',
        phone: '',
        vatid: '',
        time_zone: '',
        address: '',
        client_photo_link: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            const userEmail = localStorage.getItem('email');
            if (userEmail) {
                const profileData = await getClientProfile(userEmail);
                if (profileData) {
                    setUserData(profileData[0]);
                    if (profileData[0].client_photo_link) {
                        setProfilePhoto(`${fileBaseUrl}/${profileData[0].client_photo_link}`);
                    }
                }
            }
        };
        fetchData();
    }, []);
    console.log('previouse user data:', userData);


    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };


    const handleEdit = async (event) => {
        //userData.email = localStorage.getItem('email');
        event.preventDefault();
        try {
            console.log('userData that is sending to back:', userData);
            const response = await editClient(userData);
            if (response && response.success) {
                toast.success('Edit successful!', { position: 'top-right', autoClose: 3000 });
                setTimeout(() => window.location.href = '/dashboard-client', 3000);
            } else {
                toast.error(`Edit failed: ${response.message}`, { position: 'top-right', autoClose: 3000 });
            }
        } catch (error) {
            console.error('Edit error', error);
            toast.error('Edit failed!', { position: 'top-right', autoClose: 3000 });
        }
    };



    const handlePhotoUpload = async (event) => {
        event.preventDefault();
        const email = localStorage.getItem('email');
        if (!email) {
            console.log("No email found in local storage.");
            return;
        }
        const formData = new FormData();
        formData.append('profilePhoto', event.target.files[0]);
        formData.append('email', email);
        try {
            const response = await uploadProfilePhoto(email, formData);
            const photoPath = `${fileBaseUrl}/${response.photoPath}`; // Ensure the full URL is set
            setProfilePhoto(photoPath);
            toast.success("Profile photo uploaded successfully!");
        } catch (error) {
            console.error("Error uploading profile photo:", error);
            toast.error("Error uploading profile photo.");
        }
    };

    const handlePhotoDelete = async (event) => {
        event.preventDefault();
        const email = localStorage.getItem('email');
        if (!email) {
            console.log("No email found in local storage.");
            return;
        }

        try {
            await deleteProfilePhoto(email);
            setProfilePhoto(img1);
            toast.success("Profile photo deleted successfully!");
        } catch (error) {
            console.error("Error deleting profile photo:", error);
            toast.error("Error deleting profile photo.");
        }
    };

    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <ToastContainer />
            <PageTitle title='Edit Profile' />
            <div className="tf-section flat-explore flat-edit-profile flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="author-profile">
                                <div className="feature-profile center">
                                    <div className="wrap-img flex j-content">
                                        <div className="img-box relative">
                                            <img className="avatar" id="profileimg" src={profilePhoto} alt="" />
                                        </div>
                                    </div>
                                    <div id="upload-profile">
                                        <Link className="btn-upload" onChange={handlePhotoUpload}>Upload Your Photo</Link>
                                        <input id="tf-upload-img" type="file" onChange={handlePhotoUpload} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div className="form-upload-profile post">
                                <h3 className="title-two fw-6">Update Your Information</h3>
                                <form className="form-profile" onSubmit={handleEdit}>
                                    <div className="form-infor-profile">
                                        <div className="form-infor flex flat-form">
                                            <div className="info-account">
                                                <fieldset>
                                                    <p className="title-infor-account">First Name</p>
                                                    <input type="text" placeholder="Enter Your First Name"
                                                        id="first_name" className="tb-my-input" name="first_name"
                                                        value={userData.first_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Position in Company</p>
                                                    <input type="text" placeholder="Enter Your Position"
                                                        id="position" className="tb-my-input" name="position"
                                                        value={userData.position}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Company Name</p>
                                                    <input type="text" placeholder="Enter Your Company Name"
                                                        id="company_name" className="tb-my-input" name="company_name"
                                                        value={userData.company_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">VAT ID</p>
                                                    <input type="text" placeholder="Enter Company's VAT ID"
                                                        id="vatid" className="tb-my-input" name="vatid"
                                                        value={userData.vatid}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Address</p>
                                                    <textarea tabIndex="4" rows="3"
                                                        id="address" className="tb-my-input" name="address"
                                                        value={userData.address}
                                                        onChange={handleInputChange}></textarea>
                                                </fieldset>
                                            </div>
                                            <div className="info-social">
                                                <fieldset>
                                                    <p className="title-infor-account">Last Name</p>
                                                    <input type="text" placeholder="Enter Your Last Name"
                                                        id="last_name" className="tb-my-input" name="last_name"
                                                        value={userData.last_name}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Phone</p>
                                                    <input type="text" placeholder="+55 12333 444 55" name="phone"
                                                        id="phone" className="tb-my-input"
                                                        value={userData.phone}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Company Owner</p>
                                                    <input type="text" placeholder="Enter Name of Company Owner"
                                                        id="company_owner" className="tb-my-input" name="company_owner"
                                                        value={userData.company_owner}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Time Zone</p>
                                                    <input type="text" placeholder="Company's Location Time Zone"
                                                        id="time_zone" className="tb-my-input" name="time_zone"
                                                        value={userData.time_zone}
                                                        onChange={handleInputChange} />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="tf-button-submit mg-t-15" type="submit" onClick={handleEdit}>Save & Update Information</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer data={dataFooter} />
            <Bottom classection='widget-social-footer' />
        </div>
    );
}

export default EditProfileClient;
