import React from 'react';
import PropTypes from 'prop-types';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banneritem from '../banner-item';

Banner.propTypes = {
    data: PropTypes.array,
};

function Banner(props) {
    const { data } = props;
    return (
        <section className="flat-title-page style2 slider">
            <Swiper
                className='swiper-container mainslider home'
                modules={[Navigation, Autoplay]}
                spaceBetween={30}
                navigation
                breakpoints={{
                    1300: {
                        slidesPerView: 1,
                        spaceBetween: '3vw', // Adjusted relative to viewport width
                    },
                }}
            >
                {
                    data.slice(0, 1).map(item => (
                        <SwiperSlide key={item.id}>
                            <Banneritem item={item} />
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </section>
    );
}

export default Banner;