// my-app/src/api/auth.js
import axios from 'axios';

//---------------------1 changes for deploy
import { API_URL } from './constants';

export const registerClient = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-client`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};

export const registerFreelancer = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-freelancer`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginClient = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-client`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const loginFreelancer = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-freelancer`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const editClient = async (userData) => {
  try {
    const response = await axios.put(`${API_URL}/editProfile-client`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};

export const editFreelancer = async (userData) => {
  try {
    const response = await axios.put(`${API_URL}/editProfile-freelancer`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};