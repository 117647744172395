import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Profile from '../components/layouts/freelancerdashboard/dashboard.jsx';
import dataProfile from '../assets/fake-data/data-freelancer-dashboard.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import { fetchRequests } from '../api/freelancerDashboardApi.js'; // Import the fetchRequests function
import { fetchFreelancerData } from '../api/freelancerApi.js'; // Import the fetchRequests function

import imgauthor1 from '../assets/images/avatar/avt-01.png';

import icon1 from '../assets/images/icon/author-check.svg';
import icon2 from '../assets/images/icon/icon-diamond.svg';

import img1 from '../assets/images/box-item/auctions-1.jpg';
import img2 from '../assets/images/box-item/auctions-2.jpg';
import img3 from '../assets/images/box-item/auctions-3.jpg';
import img4 from '../assets/images/box-item/auctions-4.jpg';
import img5 from '../assets/images/box-item/auctions-5.jpg';

function FreelancerDashboard(props) {
    const [profilePhoto, setProfilePhoto] = useState();
    //Fetch Project Request from Backend
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data


    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchData = async () => {
            try {
                const freelancerData = await fetchFreelancerData(localStorage.getItem('email'));

                if (!freelancerData) {
                    // Redirect to /login-freelancer if freelancerData is not available
                    navigate('/register-freelancer');
                } else {
                    // Set the first name and last name to localStorage
                    localStorage.setItem('firstName', freelancerData.first_name);
                    localStorage.setItem('lastName', freelancerData.last_name);
                    setProfilePhoto(freelancerData.client_photo_link)
                }

                const requests = await fetchRequests();
                setRequestsData(requests);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [navigate]);

    // Define the list of images
    const images = [img1, img2, img3, img4, img5];

    // Map the fetched Requests data to match the structure of dataLiveaution
    const mappedRequestsData = requestsData.map((request, index) => ({
        id: `${request.project_id}`,
        img: images[index % images.length], // Use the modulo operator to cycle through the images
        tags: '6 New Freelancers',
        imgauthor: imgauthor1,
        title: `${request.title}`, // Update with fetched Requests number
        author: `${request.client_first_name} ${request.client_last_name}`,
        date: `${request.creation_date}`,
        titleprice: `${(request.budget / 1).toFixed(2)} EUR`, // Update with fetched price
        btn: 'Check it!',
        icon1: icon1,
        icon2: icon2,
        titlecountdown: 'Ending Time -',
        status: `${request.status}`,
        freelancer_email: `${request.freelancer_email}`
    }));

    // Determine the number of rows to display based on the length of the fetched Requests data
    const numRowsToShow = mappedRequestsData.length > 3 ? 3 : mappedRequestsData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedRequestsData = mappedRequestsData.slice(0, numRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedDataLiveaution = [...mappedRequestsData, ...slicedRequestsData.slice(mappedRequestsData.length)];

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Dashboard' />
            {<Profile data={updatedDataLiveaution} avatar={profilePhoto}/>}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default FreelancerDashboard;
