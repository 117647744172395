import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Author = (props) => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const data = props.data;

  // Check if there is no data
  if (!data || data.length === 0) {
    return (
      <div className="tf-section flat-top-seller flat-auctions dark-style page">

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3>So far, no proposal has been received from the freelancer. As soon as a proposal is received, it will be displayed on this page.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="tf-section flat-top-seller flat-auctions dark-style page">
        <div className="container">

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Swiper
                className='swiper-container carousel-7 show-shadow btn-collection2'
                spaceBetween={30}
                navigation
                breakpoints={{
                  576: {
                    slidesPerView: 1, // On smaller screens, show 1 slide per view
                  },
                  992: {
                    slidesPerView: 2, // On screens 576px and larger, show 2 slides per view
                  },
                  1200: {
                    slidesPerView: 3, // On screens 992px and larger, show 3 slides per view
                  },
                }}
              >
                {data.slice(0, 1000).map(item => (
                  <SwiperSlide key={item.id}>
                    <div className="wrap-seller center" style={{ width: 'calc(100% / 1.6)', margin: '1em' }}>
                      <div className="content">
                        <h3><Link to={{ pathname: "/project-proposal", search: `?id=${item.proposalId}` }}>{item.title}</Link></h3>
                        <div className="price flex">
                          <img src={item.icon2} alt="" />
                          <div className="title-price fs-16">{item.price}</div>
                        </div>
                        <div className="button-follow">
                          <Link to={{ pathname: "/project-proposal", search: `?id=${item.proposalId}` }} className="sc-button btn-6"><span>{item.btn}</span></Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="back-arrow"
            style={{  fontSize: "1.5em" }}
            onClick={() => navigate(-1)}
          >
            <button>
            &#8592; Back
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Author;
