import React , { useState } from 'react';
import PropTypes from 'prop-types';
import Categoryitem from '../cetagorie-item2';

Category.propTypes = {
    data : PropTypes.array,
};

function Category (props) {
    const {data} = props;
    const [dataTitle] = useState(
        {   
            title : 'Our Diverse Community of Film Specialists',
            desc: "Step into the world of cinema excellence with ShotAgent's array of specialist categories, spanning from visionary directors and masterful screenwriters to meticulous editors and innovative lighting technicians. Discover and connect with the industry's finest talent, shaping every frame with expertise and creativity.",
        }
    )
    return (
        <div className="tf-section flat-cetagories2 home2">
        <div className="container">
            <div className="row">  
                <div className="col-lg-12">
                    <div className="heading-section center">
                        <h2>{dataTitle.title}</h2>
                        <p>{dataTitle.desc}</p>
                    </div>
                </div>
                {
                    data.slice(0,15).map (item => (
                        <Categoryitem key={item.id} item={item}/>
                    ))
                }
            </div>
        </div>
    </div>
    );
}

export default Category;