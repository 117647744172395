import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProposals } from '../api/applicantsApi.js';

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Applicant from '../components/layouts/applicant/applicant.jsx';
import dataApplicants from '../assets/fake-data/data-applicants.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

//import img1 from '../assets/images/icon/icon1.svg';
import img1 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

import icon1 from '../assets/images/icon/icon-check.svg';
import icon2 from '../assets/images/icon/icon-diamond.svg';

function Applicants(props) {

        //Fetch Project Proposal from Backend

        const location = useLocation();
        const searchParams = new URLSearchParams(location.search);
        const projectId = searchParams.get('id');

        const [proposalsData, setProposalsData] = useState([]); // State to store fetched Proposals data

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const proposals = await fetchProposals(projectId); // Fetch Proposals data from backend API
                    setProposalsData(proposals[0]); // Set fetched Proposals data to state
                    console.log('proposals data in front',proposals[0] )
                } catch (error) {
                    console.error('Error fetching Proposals:', error);
                    // Handle error
                }
            };
    
            fetchData();
        }, []);
    
        // Map the fetched Proposals data to match the structure of dataLiveaution
        const mappedProposalsData = proposalsData.map((proposal, index) => ({
            id: `${proposal.proposal_id}`,
            img: img1,
            tags: '6 New Freelancers',
            title: `${proposal.freelancer_first_name} ${proposal.freelancer_last_name}`,
            price: `${proposal.freelancer_cost}`,
            btn: 'Check Proposal!',
            icon1 : icon1,
            icon2 : icon2,
            proposalId: `${proposal.proposal_id}`,
        }));
    
        // Determine the number of rows to display based on the length of the fetched Proposals data
        const numRowsToShow = mappedProposalsData.length > 3 ? 3 : mappedProposalsData.length;
    
        // Slice the mapped Proposals data to display only the required number of rows
        const slicedProposalsData = mappedProposalsData.slice(0, numRowsToShow);
    
        // Combine the mapped Proposals data with the remaining dataLiveaution
        const updatedDataLiveaution = [...mappedProposalsData, ...slicedProposalsData.slice(mappedProposalsData.length)];
    

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Project Applicants'  />
            {<Applicant data={updatedDataLiveaution}/>}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Applicants;
