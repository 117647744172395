import img1 from '../images/blog/sobhimoghadam.jpg';
import img2 from '../images/blog/shafidokht.jpg';
import img3 from '../images/blog/jahanara.jpg';

import imgavatar1 from '../images/avatar/avt-01.png';
import imgavatar2 from '../images/avatar/avt-02.png';
import imgavatar3 from '../images/avatar/avt-03.png';

const data = [
    {
        id: 1,
        img: img1,
        title: 'Shahin Sobhimoghadam',
        desc: 'He brings over 14 years of leadership experience with extensive expertise in project planning, design, and technology management.',
        imgavatar: imgavatar1,
        author: 'Cofounder & CEO',
        date: '04/10/2022',
    },
    {
        id: 2,
        img: img2,
        title: 'Hanieh Shafidokht',
        desc: 'She boasts a rich background as a construction director, sales manager, and accountant, coupled with adept project management skills.',
        imgavatar: imgavatar2,
        author: 'Cofounder & COO',
        date: '04/10/2022',
    },
    {
        id: 3,
        img: img3,
        title: 'Seyed Hamzeh Jahanara',
        desc: 'With a robust tenure as CEO and Chairman of the Board of Directors for 15 years, coupled with extensive experience in advertisement.',
        imgavatar: imgavatar3,
        author: 'Cofounder & CMO',
        date: '04/10/2022',
    },

]

export default data;