//const API_URL = "http://localhost:5005/api"; //Local Address
const API_URL = "https://api.shotagent.com/api"; // Server Address

export { API_URL };

///File Location
//const fileBaseUrl = "http://localhost:5005/uploads"; // Change this to your server's base URL
const fileBaseUrl = "https://api.shotagent.com/uploads"; // Server Address

export { fileBaseUrl };

const SHOTAGENT_WALLET_ADDRESS = '0xd0B93e3F82838282fFc288786DD8718FC92c7976';
export { SHOTAGENT_WALLET_ADDRESS };
