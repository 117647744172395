import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProjectById, submitProposal } from '../api/projectDetailApi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";
import img1 from '../assets/images/icon/icon1.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';


//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ProjectDetail(props) {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [dataContact] = useState(
        [
            {
                img: img1,
                text: 'Contact Phone',
                adress: '+55 (9900) 666',
            },
            {
                img: img2,
                text: 'Contact Mail',
                adress: 'info.nft@gmail.com',
            },
            {
                img: img3,
                text: 'Contact Location',
                adress: '18/2 Pollobi, Australia.',
            }
        ]
    )


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');

    const [project, setProject] = useState(null);

    const [freelancerCost, setFreelancerCost] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const projectData = await fetchProjectById(projectId);
                setProject(projectData);
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
        };
        fetchProject();
    }, [projectId]);


    console.log('project by id in front:', project);


    //proposal submission

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            // Submit the proposal to the backend
            await submitProposal({
                projectId,
                clientEmail: project[0].client_email,
                clientFirstName: project[0].client_first_name,
                clientLastName: project[0].client_last_name,
                freelancerEmail: localStorage.getItem('email'),
                freelancerFirstName: localStorage.getItem('firstName'),
                freelancerLastName: localStorage.getItem('lastName'),
                proposalDescription,
                freelancerCost
            });

            setRequestSuccess(true); // Set the registration success state to true

            // Show success notification
            toast.success('Submission successful!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = '/dashboard-freelancer';
            }, 3000);

        } catch (error) {
            console.error('Error submitting proposal:', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
            } else {
                toast.error('Submission failed!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Project Description' />

            <section className="tf-section tf-map">

                <div className="tf-section flat-explore flat-edit-profile flat-auctions-details ">

                    <div className="container">
                        <div className="form-upload-profile post">
                            <form action="#" className="form-profile">

                                <div className="form-infor-profile">
                                    <div className="form-infor flex flat-form">

                                        <div className="info-account">

                                            {project[0].collaboration_period && (
                                                <fieldset >
                                                    <h3 className="title-infor-account">Collaboration Period:</h3>
                                                    <p className="title-infor-account">{project[0].collaboration_period}</p>
                                                </fieldset>
                                            )}
                                            {project[0].size && (

                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Size:</h3>
                                                    <p className="title-infor-account">{project[0].size}</p>
                                                </fieldset>
                                            )}
                                            {project[0].output_format && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Output Format(s):</h3>
                                                    <p className="title-infor-account">{project[0].output_format}</p>
                                                </fieldset>
                                            )}
                                            {project[0].project_tags && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Tags:</h3>
                                                    <p className="title-infor-account">{project[0].project_tags}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project[0].title && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Title:</h3>
                                                    <p className="title-infor-account">{project[0].title}</p>
                                                </fieldset>
                                            )}
                                            {project[0].experience_level && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required experience level:</h3>
                                                    <p className="title-infor-account">{project[0].experience_level}</p>
                                                </fieldset>
                                            )}
                                            {project[0].fps && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Number of frames per second:</h3>
                                                    <p className="title-infor-account">{project[0].fps}</p>
                                                </fieldset>
                                            )}
                                            {project[0].project_description && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Description:</h3>
                                                    <p className="title-infor-account">{project[0].project_description}</p>

                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project[0].category && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Category:</h3>
                                                    <p className="title-infor-account">{project[0].category}</p>
                                                </fieldset>
                                            )}
                                            {project[0].budget && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Budget:</h3>
                                                    <p className="title-infor-account">{project[0].budget} EUR</p>
                                                </fieldset>
                                            )}
                                            {project[0].fram_size && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Frame size:</h3>
                                                    <p className="title-infor-account">{project[0].fram_size}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-infor flex flat-form">
                                        <div className="info-account">
                                            {project[0].shotDuration && (
                                                <fieldset >
                                                    <h3 className="title-infor-account">Shot Duration:</h3>
                                                    <p className="title-infor-account">{project[0].shotDuration}</p>
                                                </fieldset>
                                            )}
                                            {project[0].light && (

                                                <fieldset>
                                                    <h3 className="title-infor-account">Light Conditions:</h3>
                                                    <p className="title-infor-account">{project[0].light}</p>
                                                </fieldset>
                                            )}
                                            {project[0].exposure && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Exposure Status:</h3>
                                                    <p className="title-infor-account">{project[0].exposure}</p>
                                                </fieldset>
                                            )}
                                            {project[0].imageDepth && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Image Depth Status:</h3>
                                                    <p className="title-infor-account">{project[0].imageDepth}</p>
                                                </fieldset>
                                            )}
                                            {project[0].camera && (

                                                <fieldset>
                                                    <h3 className="title-infor-account">Recommended Camera:</h3>
                                                    <p className="title-infor-account">{project[0].camera}</p>
                                                </fieldset>
                                            )}
                                            {project[0].lens && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Recommended Lens:</h3>
                                                    <p className="title-infor-account">{project[0].lens}</p>
                                                </fieldset>
                                            )}
                                            {project[0].quality && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Output Image Quality:</h3>
                                                    <p className="title-infor-account">{project[0].quality}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project[0].color && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Status of Dominant Colors:</h3>
                                                    <p className="title-infor-account">{project[0].color}</p>
                                                </fieldset>
                                            )}
                                            {project[0].inferiorcolor && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Inferior Colors:</h3>
                                                    <p className="title-infor-account">{project[0].inferiorcolor}</p>
                                                </fieldset>
                                            )}
                                            {project[0].tiltMovement && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Tilt Movement Status:</h3>
                                                    <p className="title-infor-account">{project[0].tiltMovement}</p>
                                                </fieldset>
                                            )}
                                            {project[0].panMovement && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Pan Movement Status:</h3>
                                                    <p className="title-infor-account">{project[0].panMovement}</p>

                                                </fieldset>
                                            )}
                                            {project[0].craneMovement && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Crane Movement Status:</h3>
                                                    <p className="title-infor-account">{project[0].craneMovement}</p>
                                                </fieldset>
                                            )}
                                            {project[0].dollyMovement && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Dolly Movement Status:</h3>
                                                    <p className="title-infor-account">{project[0].dollyMovement}</p>
                                                </fieldset>
                                            )}
                                            {project[0].aerialShot && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Aerial Shot Movement Status:</h3>
                                                    <p className="title-infor-account">{project[0].aerialShot}</p>

                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project[0].objectsInView && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">State of Objects in the View:</h3>
                                                    <p className="title-infor-account">{project[0].objectsInView}</p>
                                                </fieldset>
                                            )}
                                            {project[0].descriptionOfObject && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Description of Each Object in the View:</h3>
                                                    <p className="title-infor-account">{project[0].descriptionOfObject}</p>
                                                </fieldset>
                                            )}
                                            {project[0].objectRatio && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Permutation and Ratio of the Part to the Whole of Each Object in Each View:</h3>
                                                    <p className="title-infor-account">{project[0].objectRatio}</p>
                                                </fieldset>
                                            )}
                                            {project[0].numberOfActors && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Number of Possible Actors:</h3>
                                                    <p className="title-infor-account">{project[0].numberOfActors}</p>
                                                </fieldset>
                                            )}
                                            {project[0].actsOfActor && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Acts of Each Actor:</h3>
                                                    <p className="title-infor-account">{project[0].actsOfActor}</p>
                                                </fieldset>
                                            )}
                                            {project[0].innovation && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Expected Innovative Technique:</h3>
                                                    <p className="title-infor-account">{project[0].innovation}</p>
                                                </fieldset>
                                            )}
                                            {project[0].storyboard && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Add Storyboard:</h3>
                                                    <p className="title-infor-account">{project[0].storyboard}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div className="back-arrow"
                                style={{ cursor: "pointer", fontSize: "1.5em" }}
                                onClick={() => navigate(-1)}
                            >
                                &#8592; Back
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=" flat-contact flat-edit-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-contact">
                                <div className="heading center">
                                    <h2 className="fw-6">Fill Your Proposal</h2>
                                </div>
                                <div className="form-upload-profile post">
                                    <form action="#" className="form-profile" >
                                        <div className="form-infor-profile">
                                            <div className="form-infor flex flat-form">
                                                <div className="info-account">
                                                    <h3> What is the price of this project for you? </h3>

                                                </div>
                                                <div className="info-social">
                                                    <fieldset>
                                                        <input type="number" placeholder="Price (IN EUR)"
                                                            value={freelancerCost} onChange={(e) => setFreelancerCost(e.target.value)}
                                                            required />
                                                    </fieldset>


                                                </div>
                                            </div>

                                            <fieldset className="message">
                                                <textarea placeholder="Cover Letter" tabIndex="4" rows="3"
                                                    value={proposalDescription} onChange={(e) => setProposalDescription(e.target.value)}
                                                    required></textarea>
                                            </fieldset>
                                        </div>
                                        <div className="button-contact center">
                                            <h4 style={{ marginBottom: "1em" }}>Required Coins for Sumbission: 0 Coins <br /></h4>

                                            <button className="submit" type="submit" onClick={handleFormSubmit} >
                                                Submit Now
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default ProjectDetail;