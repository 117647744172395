import React, { useState, Fragment, useEffect } from "react";

import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/profile-photo.jpg';
import icon from '../../.././assets/images/icon/icon-check.svg';
import icon2 from '../../.././assets/images/icon/icon-diamond.svg';
//import img2 from '../../.././assets/images/box-item/profile-cover.jpg';
import { Dropdown } from "react-bootstrap";

//submit Request
//Bckend Connection
import { submitRequest } from "../../../api/requestApi";

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from 'react-router-dom';
import { updateRequest } from '../../../api/projectEditApi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook


const Profile1 = (props) => {
    const location = useLocation();
    const initialProject = location.state?.project;

    console.log('Received project:', initialProject);

    const navigate = useNavigate(); // Initialize useNavigate hook


    const data = props.data;
    const [modalShow, setModalShow] = useState(false);


    const stored_email = localStorage.getItem('email');
    const stored_first_name = localStorage.getItem('firstName');
    const stored_last_name = localStorage.getItem('lastName');

    const [requestData, setRequestData] = useState(initialProject[0]);


    //fetch project data
    //const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');


    console.log('Initial Request Data:', requestData);




    //////////////// General Settings
    //Project Period List
    const [selectedPeriod, setSelectedPeriod] = useState(initialProject[0].collaboration_period ? `${initialProject[0].collaboration_period}` : "Click to Select One of the Items Listed");
    const [periodList] = useState(
        [
            {
                id: 1,
                title: "Short Term",
            },
            {
                id: 2,
                title: "Long Term",
            },
        ]
    )
    const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false);
    const handleItemClick = (title) => {
        setSelectedPeriod(title);
        setIsPeriodMenuOpen(false);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'collaboration_period', value: title } });
    };

    const handlePeriodMenuToggle = (isOpen) => {
        setIsPeriodMenuOpen(isOpen);
    };

    //category list
    const [selectedCategory, setSelectedCategory] = useState(initialProject[0].category ? `${initialProject[0].category}` : "Click to Select One of the Items Listed");
    const [categoryList] = useState(
        [
            {
                id: 1,
                title: "Promotional Videos",
            },
            {
                id: 2,
                title: "Documentary",
            },
            {
                id: 3,
                title: "Short Film",
            },
            {
                id: 4,
                title: "Feature film",
            },
            {
                id: 5,
                title: "Historical movie",
            },
        ]
    )
    const handleCategoryClick = (title) => {
        setSelectedCategory(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'category', value: title } });
    };

    //Projcet Size list
    const [selectedSize, setSelectedSize] = useState(initialProject[0].size ? `${initialProject[0].size}` : "Click to Select One of the Items Listed");
    const [sizeList] = useState(
        [
            {
                id: 1,
                title: "Small",
            },
            {
                id: 2,
                title: "Medium",
            },
            {
                id: 3,
                title: "Large",
            },
        ]
    )
    const handleSizeClick = (title) => {
        setSelectedSize(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'size', value: title } });
    };

    //Projcet Experience list
    const [selectedExp, setSelectedExp] = useState(initialProject[0].experience_level ? `${initialProject[0].experience_level}` : "Click to Select One of the Items Listed");
    const [expList] = useState(
        [
            {
                id: 1,
                title: "Entry",
            },
            {
                id: 2,
                title: "Intermediate",
            },
            {
                id: 3,
                title: "Expert",
            },
        ]
    )
    const handleExpClick = (title) => {
        setSelectedExp(title);
        // Call handleInputChange with the selected title
        handleInputChange({ target: { name: 'experience_level', value: title } });
    };


    ///////////////////////Advanced Settings
    //Projcet Experience list
    const [selectedShotDuration, setSelectedShotDuration] = useState(initialProject[0].shotDuration ? `${initialProject[0].shotDuration}` : "Click to Select One of the Items Listed");
    const [shotDurationList] = useState(
        [
            {
                id: 1,
                title: "Ultra Short (less than 1 second)",
            },
            {
                id: 2,
                title: "Very Short (1-3 seconds)",
            },
            {
                id: 3,
                title: "Short (3-5 seconds)",
            },
            {
                id: 4,
                title: "Quick Cut (5-10 seconds)",
            },
            {
                id: 5,
                title: "Standard (10-20 seconds)",
            },
            {
                id: 6,
                title: "Medium (20-30 seconds)",
            },
            {
                id: 7,
                title: "Long (30 seconds to 1 minute)",
            },
            {
                id: 8,
                title: "Extended (1-2 minutes)",
            },
            {
                id: 9,
                title: "Very Long (2-5 minutes)",
            },
            {
                id: 10,
                title: "Epic (more than 5 minutes)",
            },
        ]
    )
    const handleShotDurationClick = (title) => {
        setSelectedShotDuration(title);
        handleInputChange({ target: { name: 'shotDuration', value: title } });
    };


    //Projcet Light Conditions 
    const [selectedLight, setSelectedLight] = useState(initialProject[0].light ? `${initialProject[0].light}` : "Click to Select One of the Items Listed");
    const [lightList] = useState(
        [
            {
                id: 1,
                title: "Natural Sunlight",
            },
            {
                id: 2,
                title: "Studio Lighting",
            },
            {
                id: 3,
                title: "Low Light",
            },
            {
                id: 4,
                title: "High Contrast",
            },
            {
                id: 5,
                title: "Golden Hour",
            },
            {
                id: 6,
                title: "Overcast",
            },
            {
                id: 7,
                title: "Artificial Lighting",
            },
            {
                id: 8,
                title: "Candlelight",
            },
            {
                id: 9,
                title: "Soft Diffused Light",
            },
            {
                id: 10,
                title: "Backlit",
            },
            {
                id: 11,
                title: "Twilight",
            },
            {
                id: 12,
                title: "Sunset",
            },
            {
                id: 13,
                title: "Sunrise",
            },
            {
                id: 14,
                title: "Twilight",
            },
            {
                id: 15,
                title: "Magic Hour (the period shortly after sunrise or before sunset)",
            },
            {
                id: 16,
                title: "Neon Lighting",
            },
            {
                id: 17,
                title: "Spotlight",
            },
            {
                id: 18,
                title: "Firelight",
            },
            {
                id: 19,
                title: "Sunrise/Sunset on the Horizon",
            },
            {
                id: 20,
                title: "Harsh Midday Sun",
            },
        ]
    )
    const handleLightClick = (title) => {
        setSelectedLight(title);
        handleInputChange({ target: { name: 'light', value: title } });
    };

    //Projcet Exposure Status 
    const [selectedExposure, setSelectedExposure] = useState(initialProject[0].exposure ? `${initialProject[0].exposure}` : "Click to Select One of the Items Listed");
    const [exposureList] = useState(
        [
            {
                id: 1,
                title: "Overexposed Highlights",
            },
            {
                id: 2,
                title: "Underexposed Shadows",
            },
            {
                id: 3,
                title: "Soft Highlights",
            },
            {
                id: 4,
                title: "Crushed Blacks",
            },
            {
                id: 5,
                title: "High-Key (bright and low contrast)",
            },
            {
                id: 6,
                title: "Low-Key (dark and high contrast)",
            },
            {
                id: 7,
                title: "Balanced Exposure",
            },
            {
                id: 8,
                title: "Silhouette with Backlighting",
            },
            {
                id: 9,
                title: "Lens Flare Effect",
            },
            {
                id: 10,
                title: "High Dynamic Range (HDR)",
            },
            {
                id: 11,
                title: "Lens Aberrations (e.g., vignetting)",
            },
            {
                id: 12,
                title: "Lens Glare",
            },
            {
                id: 13,
                title: "Bokeh (blurred background)",
            },
            {
                id: 14,
                title: "Lens Distortion (e.g., fisheye effect)",
            },
            {
                id: 15,
                title: "Lens Blur",
            },
            {
                id: 16,
                title: "Infrared (IR) Photography",
            },
            {
                id: 17,
                title: "Double Exposure",
            },
            {
                id: 18,
                title: "Solarization Effect",
            },
            {
                id: 19,
                title: "Grainy Texture",
            },
            {
                id: 20,
                title: "Soft Focus",
            },
        ]
    )
    const handleExposureClick = (title) => {
        setSelectedExposure(title);
        handleInputChange({ target: { name: 'exposure', value: title } });
    };

    //Projcet Image Depth 
    const [selectedImageDepth, setSelectedImageDepth] = useState(initialProject[0].imageDepth ? `${initialProject[0].imageDepth}` : "Click to Select One of the Items Listed");
    const [imageDepthList] = useState(
        [
            {
                id: 1,
                title: "Shallow Depth of Field (Portrait Mode)",
            },
            {
                id: 2,
                title: "Deep Depth of Field (Landscape Mode)",
            },
            {
                id: 3,
                title: "Bokeh Effect",
            },
            {
                id: 4,
                title: "Tilt-Shift Effect",
            },
            {
                id: 5,
                title: "Miniature Effect (Simulating a miniaturized world)",
            },
            {
                id: 6,
                title: "Swirl Bokeh (Distinctive swirling background blur)",
            },
            {
                id: 7,
                title: "Selective Focus",
            },
            {
                id: 8,
                title: "Soft Focus (Dreamy, ethereal appearance)",
            },
            {
                id: 9,
                title: "Pinhole Camera Effect",
            },
            {
                id: 10,
                title: "Split Focus (Simultaneous sharp and blurred areas)",
            },
            {
                id: 11,
                title: "Shallow Depth of Field (Portrait Mode)",
            },
            {
                id: 12,
                title: "Deep Depth of Field (Landscape Mode)",
            },
            {
                id: 13,
                title: "Bokeh Effect",
            },
            {
                id: 14,
                title: "Tilt-Shift Effect",
            },
            {
                id: 15,
                title: "Miniature Effect (Simulating a miniaturized world)",
            },
            {
                id: 16,
                title: "Swirl Bokeh (Distinctive swirling background blur)",
            },
            {
                id: 17,
                title: "Selective Focus",
            },
            {
                id: 18,
                title: "Soft Focus (Dreamy, ethereal appearance)",
            },
            {
                id: 19,
                title: "Pinhole Camera Effect",
            },
            {
                id: 20,
                title: "Split Focus (Simultaneous sharp and blurred areas)",
            },
        ]
    )
    const handleImageDepthClick = (title) => {
        setSelectedImageDepth(title);
        handleInputChange({ target: { name: 'imageDepth', value: title } });
    };

    //Projcet Camera
    const [selectedCamera, setSelectedCamera] = useState(initialProject[0].camera ? `${initialProject[0].camera}` : "Click to Select One of the Items Listed");
    const [cameraList] = useState(
        [
            {
                id: 1,
                title: "Digital Single-Lens Reflex (DSLR)",
            },
            {
                id: 2,
                title: "Mirrorless",
            },
            {
                id: 3,
                title: "Camcorder",
            },
            {
                id: 4,
                title: "Smartphone",
            },
            {
                id: 5,
                title: "Action Camera",
            },
            {
                id: 6,
                title: "Cinema Camera",
            },
            {
                id: 7,
                title: "Medium Format Camera",
            },
            {
                id: 8,
                title: "Film Camera (Analog)",
            },
            {
                id: 9,
                title: "360-Degree Camera",
            },
            {
                id: 10,
                title: "Compact Digital Camera",
            },
            {
                id: 11,
                title: "Large Format Camera",
            },
            {
                id: 12,
                title: "Point-and-Shoot Camera",
            },
            {
                id: 13,
                title: "Webcam",
            },
            {
                id: 14,
                title: "Aerial Drone Camera",
            },
            {
                id: 15,
                title: "Underwater Camera",
            },
            {
                id: 16,
                title: "3D Stereoscopic Camera",
            },
            {
                id: 17,
                title: "Thermal Imaging Camera",
            },
            {
                id: 18,
                title: "High-Speed Camera (Slow-motion)",
            },
            {
                id: 19,
                title: "Infrared (IR) Camera",
            },
            {
                id: 20,
                title: "Time-Lapse Camera",
            },
        ]
    )
    const handleCameraClick = (title) => {
        setSelectedCamera(title);
        handleInputChange({ target: { name: 'camera', value: title } });
    };

    //Projcet Lens
    const [selectedLens, setSelectedLens] = useState(initialProject[0].lens ? `${initialProject[0].lens}` : "Click to Select One of the Items Listed");
    const [lensList] = useState(
        [
            {
                id: 1,
                title: "Wide-Angle Prime",
            },
            {
                id: 2,
                title: "Standard Prime",
            },
            {
                id: 3,
                title: "Telephoto Prime",
            },
            {
                id: 4,
                title: "Macro Prime",
            },
            {
                id: 5,
                title: "Zoom Lens (e.g., 24-70mm)",
            },
            {
                id: 6,
                title: "Ultra-Wide Angle Zoom",
            },
            {
                id: 7,
                title: "Telephoto Zoom",
            },
            {
                id: 8,
                title: "Fisheye Lens",
            },
            {
                id: 9,
                title: "Tilt-Shift Lens",
            },
            {
                id: 10,
                title: "Pancake Lens",
            },
            {
                id: 11,
                title: "Fixed Aperture Lens",
            },
            {
                id: 12,
                title: "Variable Aperture Lens",
            },
            {
                id: 13,
                title: "Portrait Lens",
            },
            {
                id: 14,
                title: "Fish-Eye Zoom",
            },
            {
                id: 15,
                title: "Anamorphic Lens",
            },
            {
                id: 16,
                title: "Vintage Lens (e.g., for retro look)",
            },
            {
                id: 17,
                title: "Soft Focus Lens",
            },
            {
                id: 18,
                title: "Close-Up Lens (for macro shots)",
            },
            {
                id: 19,
                title: "Specialty Lens (e.g., Lensbaby)",
            },
            {
                id: 20,
                title: "Prime Lens with Image Stabilization",
            },
        ]
    )
    const handleLensClick = (title) => {
        setSelectedLens(title);
        handleInputChange({ target: { name: 'lens', value: title } });
    };

    //Projcet Image Quality
    const [selectedQuality, setSelectedQuality] = useState(initialProject[0].quality ? `${initialProject[0].quality}` : "Click to Select One of the Items Listed");
    const [qualityList] = useState(
        [
            {
                id: 1,
                title: "Basic Resolution (e.g., 720p)",
            },
            {
                id: 2,
                title: "High Definition (HD - 1080p)",
            },
            {
                id: 3,
                title: "Ultra High Definition (4K)",
            },
            {
                id: 4,
                title: "Full High Definition (FHD - 2K)",
            },
            {
                id: 5,
                title: "Cinema 4K (DCI 4K)",
            },
            {
                id: 6,
                title: "RAW (Uncompressed)",
            },
            {
                id: 7,
                title: "ProRes Format",
            },
            {
                id: 8,
                title: "HDR (High Dynamic Range)",
            },
            {
                id: 9,
                title: "H.265 (HEVC) Codec",
            },
            {
                id: 10,
                title: "H.264 Codec",
            },
            {
                id: 11,
                title: "Lossless Compression",
            },
            {
                id: 12,
                title: "10-bit Color Depth",
            },
            {
                id: 13,
                title: "12-bit Color Depth",
            },
            {
                id: 14,
                title: "16-bit Color Depth",
            },
            {
                id: 15,
                title: "Log Color Profile",
            },
            {
                id: 16,
                title: "Rec. 709 Color Space",
            },
            {
                id: 17,
                title: "Wide Color Gamut (e.g., AdobeRGB)",
            },
            {
                id: 18,
                title: "Color Grading-Friendly",
            },
            {
                id: 19,
                title: "High Bitrate Encoding",
            },
            {
                id: 20,
                title: "Variable Frame Rate (VFR)",
            },
        ]
    )
    const handleQualityClick = (title) => {
        setSelectedQuality(title);
        handleInputChange({ target: { name: 'quality', value: title } });

    };


    //Projcet Inferior Colors
    const [selectedColor, setSelectedColor] = useState(initialProject[0].color ? `${initialProject[0].color}` : "Click to Select One of the Items Listed");
    const [colorList] = useState(
        [
            {
                id: 1,
                title: "Desaturated and Faded",
            },
            {
                id: 2,
                title: "Muted and Subdued",
            },
            {
                id: 3,
                title: "Vintage and Sepia",
            },
            {
                id: 4,
                title: "Black and White (Monochrome)",
            },
            {
                id: 5,
                title: "Cool and Tonal Grays",
            },
            {
                id: 6,
                title: "Warm and Tonal Browns",
            },
            {
                id: 7,
                title: "Monotone (Single Hue)",
            },
            {
                id: 8,
                title: "Tinted (e.g., Blue or Red Tones)",
            },
            {
                id: 9,
                title: "High-Contrast Black and White",
            },
            {
                id: 10,
                title: "Hand-Painted or Hand-Tinted",
            },
            {
                id: 11,
                title: "Low Saturation Pastels",
            },
            {
                id: 12,
                title: "Underexposed Colors",
            },
            {
                id: 13,
                title: "Infrared (IR) Color",
            },
            {
                id: 14,
                title: "Duotone (Two-Color Palette)",
            },
            {
                id: 15,
                title: "Tri-tone (Three-Color Palette)",
            },
            {
                id: 16,
                title: "Antique or Aged Appearance",
            },
            {
                id: 17,
                title: "Solarized Colors",
            },
            {
                id: 18,
                title: "Vintage Hand-Colored Look",
            },
            {
                id: 19,
                title: "Minimalist Color Palette",
            },
            {
                id: 20,
                title: "Watercolor Effect",
            },
        ]
    )
    const handleColorClick = (title) => {
        setSelectedColor(title);
        handleInputChange({ target: { name: 'color', value: title } });
    };


    //Projcet Inferior Colors
    const [selectedInferiorColor, setSelectedInferiorColor] = useState(initialProject[0].inferiorcolor ? `${initialProject[0].inferiorcolor}` : "Click to Select One of the Items Listed");
    const [inferiorcolorList] = useState(
        [
            {
                id: 1,
                title: "Desaturated and Faded",
            },
            {
                id: 2,
                title: "Muted and Subdued",
            },
            {
                id: 3,
                title: "Vintage and Sepia",
            },
            {
                id: 4,
                title: "Black and White (Monochrome)",
            },
            {
                id: 5,
                title: "Cool and Tonal Grays",
            },
            {
                id: 6,
                title: "Warm and Tonal Browns",
            },
            {
                id: 7,
                title: "Monotone (Single Hue)",
            },
            {
                id: 8,
                title: "Tinted (e.g., Blue or Red Tones)",
            },
            {
                id: 9,
                title: "High-Contrast Black and White",
            },
            {
                id: 10,
                title: "Hand-Painted or Hand-Tinted",
            },
            {
                id: 11,
                title: "Low Saturation Pastels",
            },
            {
                id: 12,
                title: "Underexposed Colors",
            },
            {
                id: 13,
                title: "Infrared (IR) Color",
            },
            {
                id: 14,
                title: "Duotone (Two-Color Palette)",
            },
            {
                id: 15,
                title: "Tri-tone (Three-Color Palette)",
            },
            {
                id: 16,
                title: "Antique or Aged Appearance",
            },
            {
                id: 17,
                title: "Solarized Colors",
            },
            {
                id: 18,
                title: "Vintage Hand-Colored Look",
            },
            {
                id: 19,
                title: "Minimalist Color Palette",
            },
            {
                id: 20,
                title: "Watercolor Effect",
            },
        ]
    )
    const handleInferiorColorClick = (title) => {
        setSelectedInferiorColor(title);
        handleInputChange({ target: { name: 'inferiorcolor', value: title } });
    };

    //State of Tilt Movement Status
    const [selectedTiltMovement, setSelectedTiltMovement] = useState(initialProject[0].tiltMovement ? `${initialProject[0].tiltMovement}` : "Click to Select One of the Items Listed");
    const [tiltMovementList] = useState(
        [
            {
                id: 1,
                title: "Gentle Upward Tilt",
            },
            {
                id: 2,
                title: "Steep Upward Tilt",
            },
            {
                id: 3,
                title: "Slow Tilt Up",
            },
            {
                id: 4,
                title: "Quick Tilt Up",
            },
            {
                id: 5,
                title: "Gentle Downward Tilt",
            },
            {
                id: 6,
                title: "Steep Downward Tilt",
            },
            {
                id: 7,
                title: "Slow Tilt Down",
            },
            {
                id: 8,
                title: "Quick Tilt Down",
            },
            {
                id: 9,
                title: "Vertical Tilt",
            },
            {
                id: 10,
                title: "Horizontal Tilt",
            },
            {
                id: 11,
                title: "Diagonal Tilt",
            },
            {
                id: 12,
                title: "Smooth Tilt",
            },
            {
                id: 13,
                title: "Jittery or Stuttering Tilt",
            },
            {
                id: 14,
                title: "Continuous Tilt Up and Down",
            },
            {
                id: 15,
                title: "Controlled Tilt Stop",
            },
            {
                id: 16,
                title: "Dramatic Tilt with Perspective Shift",
            },
            {
                id: 17,
                title: "Subtle Tilt for Emphasis",
            },
            {
                id: 18,
                title: "Tilt with Focus Transition",
            },
            {
                id: 19,
                title: "Tilt with Parallax Effect",
            },
            {
                id: 20,
                title: "Reverse Tilt for Unconventional Perspective",
            },
        ]
    )
    const handleTiltMovementClick = (title) => {
        setSelectedTiltMovement(title);
        handleInputChange({ target: { name: 'tiltMovement', value: title } });
    };

    //State of Pan Movement Status
    const [selectedPanMovement, setSelectedPanMovement] = useState(initialProject[0].panMovement ? `${initialProject[0].panMovement}` : "Click to Select One of the Items Listed");
    const [panMovementList] = useState(
        [
            {
                id: 1,
                title: "Slow Pan Left",
            },
            {
                id: 2,
                title: "Slow Pan Right",
            },
            {
                id: 3,
                title: "Quick Pan Left",
            },
            {
                id: 4,
                title: "Quick Pan Right",
            },
            {
                id: 5,
                title: "Continuous Panning",
            },
            {
                id: 6,
                title: "Controlled Pan Stop",
            },
            {
                id: 7,
                title: "Panning with Smooth Arc",
            },
            {
                id: 8,
                title: "Jerky or Stuttering Pan",
            },
            {
                id: 9,
                title: "Horizontal Pan",
            },
            {
                id: 10,
                title: "Vertical Pan",
            },
            {
                id: 11,
                title: "Diagonal Pan",
            },
            {
                id: 12,
                title: "360-Degree Pan",
            },
            {
                id: 13,
                title: "Panning with Object Tracking",
            },
            {
                id: 14,
                title: "Whip Pan (Rapid, blurred pan)",
            },
            {
                id: 15,
                title: "Sweeping Panoramic Pan",
            },
            {
                id: 16,
                title: "Panning with Tilt",
            },
            {
                id: 17,
                title: "Parallax Effect Pan",
            },
            {
                id: 18,
                title: "Spiral Pan",
            },
            {
                id: 19,
                title: "Pan with Rack Focus",
            },
            {
                id: 20,
                title: "Fast and Slow Variable Speed Panning",
            },
        ]
    )
    const handlePanMovementClick = (title) => {
        setSelectedPanMovement(title);
        handleInputChange({ target: { name: 'panMovement', value: title } });
    };

    //State of Crane Movement Status
    const [selectedCraneMovement, setSelectedCraneMovement] = useState(initialProject[0].craneMovement ? `${initialProject[0].craneMovement}` : "Click to Select One of the Items Listed");
    const [craneMovementList] = useState(
        [
            {
                id: 1,
                title: "Vertical Crane Up",
            },
            {
                id: 2,
                title: "Vertical Crane Down",
            },
            {
                id: 3,
                title: "Horizontal Crane Left",
            },
            {
                id: 4,
                title: "Horizontal Crane Right",
            },
            {
                id: 5,
                title: "Combination of Vertical and Horizontal Crane Movements",
            },
            {
                id: 6,
                title: "Controlled Crane Stop",
            },
            {
                id: 7,
                title: "Crane Lift and Lower (Variable Speed)",
            },
            {
                id: 8,
                title: "Smooth and Gradual Crane Movement",
            },
            {
                id: 9,
                title: "Jerky or Stuttering Crane Movement",
            },
            {
                id: 10,
                title: "Crane with Rotation",
            },
            {
                id: 11,
                title: "Crane with Swinging Motion",
            },
            {
                id: 12,
                title: "Crane with Tilting",
            },
            {
                id: 13,
                title: "Crane with 360-Degree Rotation",
            },
            {
                id: 14,
                title: "Crane with Object Tracking",
            },
            {
                id: 15,
                title: "Crane with Panning",
            },
            {
                id: 16,
                title: "Crane with Overhead Perspective",
            },
            {
                id: 17,
                title: "Crane with Underneath Perspective",
            },
            {
                id: 18,
                title: "Crane with Parallax Effect",
            },
            {
                id: 19,
                title: "Crane with Zooming",
            },
            {
                id: 20,
                title: "Crane with Focus Pull",
            },
        ]
    )
    const handleCraneMovementClick = (title) => {
        setSelectedCraneMovement(title);
        handleInputChange({ target: { name: 'craneMovement', value: title } });
    };


    //State of Dolly Movement Status
    const [selectedDollyMovement, setSelectedDollyMovement] = useState(initialProject[0].dollyMovement ? `${initialProject[0].dollyMovement}` : "Click to Select One of the Items Listed");
    const [dollyMovementList] = useState(
        [
            {
                id: 1,
                title: "Dolly In",
            },
            {
                id: 2,
                title: "Dolly Out",
            },
            {
                id: 3,
                title: "Slow Dolly In",
            },
            {
                id: 4,
                title: "Slow Dolly Out",
            },
            {
                id: 5,
                title: "Quick Dolly In",
            },
            {
                id: 6,
                title: "Quick Dolly Out",
            },
            {
                id: 7,
                title: "Continuous Dolly Movement",
            },
            {
                id: 8,
                title: "Controlled Dolly Stop",
            },
            {
                id: 9,
                title: "Straight Linear Dolly",
            },
            {
                id: 10,
                title: "Curved Dolly Path",
            },
            {
                id: 11,
                title: "Arc Dolly (Circular Motion)",
            },
            {
                id: 12,
                title: "Zigzag Dolly",
            },
            {
                id: 13,
                title: "Diagonal Dolly",
            },
            {
                id: 14,
                title: "Parabolic Dolly (Smooth Curve)",
            },
            {
                id: 15,
                title: "Dolly with Pan Movement",
            },
            {
                id: 16,
                title: "Dolly with Tilt Movement",
            },
            {
                id: 17,
                title: "Dolly with Crane Movement",
            },
            {
                id: 18,
                title: "Dolly with Zooming",
            },
            {
                id: 19,
                title: "Tracking Dolly (Following Subject)",
            },
            {
                id: 20,
                title: "Parallel Dolly (Maintaining Distance)",
            },
        ]
    )
    const handleDollyMovementClick = (title) => {
        setSelectedDollyMovement(title);
        handleInputChange({ target: { name: 'dollyMovement', value: title } });
    };

    //State of Aerial Shot Movement Status
    const [selectedAerialShot, setSelectedAerialShot] = useState(initialProject[0].aerialShot ? `${initialProject[0].aerialShot}` : "Click to Select One of the Items Listed");
    const [aerialShotList] = useState(
        [
            {
                id: 1,
                title: "Hovering (Stationary Aerial Shot)",
            },
            {
                id: 2,
                title: "Ascending Vertically",
            },
            {
                id: 3,
                title: "Descending Vertically",
            },
            {
                id: 4,
                title: "Forward Movement",
            },
            {
                id: 5,
                title: "Backward Movement",
            },
            {
                id: 6,
                title: "Leftward Movement",
            },
            {
                id: 7,
                title: "Rightward Movement",
            },
            {
                id: 8,
                title: "Circular Orbit (Clockwise)",
            },
            {
                id: 9,
                title: "Circular Orbit (Counter-Clockwise)",
            },
            {
                id: 10,
                title: "Helical Spiral Movement",
            },
            {
                id: 11,
                title: "Rapid Ascent and Descent",
            },
            {
                id: 12,
                title: "Sliding Movement",
            },
            {
                id: 13,
                title: "Banking Turn (Tilting)",
            },
            {
                id: 14,
                title: "Hover with Slow Pan",
            },
            {
                id: 15,
                title: "Aerial Tracking (Following Subject)",
            },
            {
                id: 16,
                title: "Top-Down Perspective",
            },
            {
                id: 17,
                title: "Bird's Eye View",
            },
            {
                id: 18,
                title: "Terrain Following (Low-Level Flight)",
            },
            {
                id: 19,
                title: "Oblique Angle View",
            },
            {
                id: 20,
                title: "Aerial Zooming",
            },
        ]
    )
    const handleAerialShotClick = (title) => {
        setSelectedAerialShot(title);
        handleInputChange({ target: { name: 'aerialShot', value: title } });
    };



    //State of Objects in the View 
    const [selectedObjectsInView, setSelectedObjectsInView] = useState(initialProject[0].objectsInView ? `${initialProject[0].objectsInView}` : "Click to Select One of the Items Listed");
    const [objectsInViewList] = useState(
        [
            {
                id: 1,
                title: "Natural Landscapes",
            },
            {
                id: 2,
                title: "Urban Landscapes",
            },
            {
                id: 3,
                title: "Architecture and Buildings",
            },
            {
                id: 4,
                title: "Interior Spaces",
            },
            {
                id: 5,
                title: "Outdoor Environments",
            },
            {
                id: 6,
                title: "People and Portraits",
            },
            {
                id: 7,
                title: "Wildlife and Animals",
            },
            {
                id: 8,
                title: "Vehicles and Transportation",
            },
            {
                id: 9,
                title: "Products and Objects",
            },
            {
                id: 10,
                title: "Art and Artifacts",
            },
            {
                id: 11,
                title: "Food and Culinary Items",
            },
            {
                id: 12,
                title: "Flora and Plants",
            },
            {
                id: 13,
                title: "Water Bodies (e.g., rivers, lakes)",
            },
            {
                id: 14,
                title: "Aerial Views of Cities",
            },
            {
                id: 15,
                title: "Underwater Scenes",
            },
            {
                id: 16,
                title: "Special Effects (e.g., explosions)",
            },
            {
                id: 17,
                title: "Studio Props and Sets",
            },
            {
                id: 18,
                title: "Abstract Patterns and Textures",
            },
            {
                id: 19,
                title: "Weather and Atmospheric Conditions",
            },
            {
                id: 20,
                title: "Crowd and Event Scenes",
            },
        ]
    )
    const handleObjectsInViewClick = (title) => {
        setSelectedObjectsInView(title);
        handleInputChange({ target: { name: 'objectsInView', value: title } });
    };



    //Description of Each Object in the View  
    const [selectedDescriptionOfObject, setSelectedDescriptionOfObject] = useState(initialProject[0].descriptionOfObject ? `${initialProject[0].descriptionOfObject}` : "Click to Select One of the Items Listed");
    const [descriptionOfObjectList] = useState(
        [
            {
                id: 1,
                title: "Specific Object Details",
            },
            {
                id: 2,
                title: "Individual's Appearance and Attire",
            },
            {
                id: 3,
                title: "Facial Expressions and Emotions",
            },
            {
                id: 4,
                title: "Animal Behavior and Characteristics",
            },
            {
                id: 5,
                title: "Vehicle Makes and Models",
            },
            {
                id: 6,
                title: "Product Branding and Features",
            },
            {
                id: 7,
                title: "Botanical and Plant Details",
            },
            {
                id: 8,
                title: "Water Reflections and Ripples",
            },
            {
                id: 9,
                title: "Material Textures and Surfaces",
            },
            {
                id: 10,
                title: "Lighting and Shadow Play",
            },
            {
                id: 11,
                title: "Cultural or Historical Significance",
            },
            {
                id: 12,
                title: "Abstract or Geometric Shapes",
            },
            {
                id: 13,
                title: "Dynamic Movement of Objects",
            },
            {
                id: 14,
                title: "Interaction Between Objects",
            },
            {
                id: 15,
                title: "Environmental Conditions (e.g., rain, fog)",
            },
            {
                id: 16,
                title: "Special Effects and CGI Elements",
            },
            {
                id: 17,
                title: "Crowd Reactions and Behavior",
            },
            {
                id: 18,
                title: "Event Details and Atmosphere",
            },
            {
                id: 19,
                title: "Inanimate Object Placement",
            },
            {
                id: 20,
                title: "Relationship Between Objects",
            },
        ]
    )
    const handleDescriptionOfObjectClick = (title) => {
        setSelectedDescriptionOfObject(title);
        handleInputChange({ target: { name: 'descriptionOfObject', value: title } });
    };

    ///Permutation and Ratio of the Part to the Whole of Each Object in Each View 
    const [selectedObjectRatio, setSelectedObjectRatio] = useState(initialProject[0].objectRatio ? `${initialProject[0].objectRatio}` : "Click to Select One of the Items Listed");
    const [objectRatioList] = useState(
        [
            {
                id: 1,
                title: "Close-Up Details (e.g., extreme close-up)",
            },
            {
                id: 2,
                title: "Half and Half (50/50)",
            },
            {
                id: 3,
                title: "The Object as a Focal Point (80/20)",
            },
            {
                id: 4,
                title: "Object in the Background (20/80)",
            },
            {
                id: 5,
                title: "Leading Lines Toward the Object",
            },
            {
                id: 6,
                title: "Objects in Symmetry",
            },
            {
                id: 7,
                title: "Objects in Asymmetry",
            },
            {
                id: 8,
                title: "Dominant Object Amidst Others",
            },
            {
                id: 9,
                title: "Object as Negative Space",
            },
            {
                id: 10,
                title: "Object Peeking Through a Frame",
            },
            {
                id: 11,
                title: "Object Blurred in the Foreground",
            },
            {
                id: 12,
                title: "Object Emerging from the Shadows",
            },
            {
                id: 13,
                title: "Object Partially Hidden",
            },
            {
                id: 14,
                title: "Object Cut Off at the Frame",
            },
            {
                id: 15,
                title: "Object in Relation to Rule of Thirds",
            },
            {
                id: 16,
                title: "Object with Motion Blur",
            },
            {
                id: 17,
                title: "Object in Dynamic Interaction",
            },
            {
                id: 18,
                title: "Object in Extreme Perspective",
            },
            {
                id: 19,
                title: "Object in Repetition or Patterns",
            },
            {
                id: 20,
                title: "Object with Sudden Reveal (Dramatic Entry)",
            },
        ]
    )
    const handleObjectRatioClick = (title) => {
        setSelectedObjectRatio(title);
        handleInputChange({ target: { name: 'objectRatio', value: title } });
    };

    ///Number of Possible Actors  
    const [selectedNumberOfActors, setSelectedNumberOfActors] = useState(initialProject[0].numberOfActors ? `${initialProject[0].numberOfActors}` : "Click to Select One of the Items Listed");
    const [numberOfActorsList] = useState(
        [
            {
                id: 1,
                title: "Solo Performer",
            },
            {
                id: 2,
                title: "Duo or Pair",
            },
            {
                id: 3,
                title: "Small Group (3-5 individuals)",
            },
            {
                id: 4,
                title: "Medium Group (6-10 individuals)",
            },
            {
                id: 5,
                title: "Large Group (11+ individuals)",
            },
            {
                id: 6,
                title: "Crowd or Audience",
            },
            {
                id: 7,
                title: "Extras or Background Actors",
            },
            {
                id: 8,
                title: "Animals as Actors",
            },
            {
                id: 9,
                title: "Non-Human Characters",
            },
            {
                id: 10,
                title: "Performer with Inanimate Object",
            },
            {
                id: 11,
                title: "Mimicry or Impersonator",
            },
            {
                id: 12,
                title: "Dancing Troupe",
            },
            {
                id: 13,
                title: "Multiple Character Roles (same actor)",
            },
            {
                id: 14,
                title: "Cameo Appearances",
            },
            {
                id: 15,
                title: "Stand-Ins or Body Doubles",
            },
            {
                id: 16,
                title: "Stunt Performers",
            },
            {
                id: 17,
                title: "Silent Actors (Mime)",
            },
            {
                id: 18,
                title: "Digital or CGI Characters",
            },
            {
                id: 19,
                title: "Interviewees or Testimonials",
            },
            {
                id: 20,
                title: "Voice-Only Actors (Narration)",
            },
        ]
    )
    const handleNumberOfActorsClick = (title) => {
        setSelectedNumberOfActors(title);
        handleInputChange({ target: { name: 'numberOfActors', value: title } });
    };

    ///Acts of Each Actor   
    const [selectedActsOfActor, setSelectedActsOfActor] = useState(initialProject[0].actsOfActor ? `${initialProject[0].actsOfActor}` : "Click to Select One of the Items Listed");
    const [actsOfActorList] = useState(
        [
            {
                id: 1,
                title: "Dialogue and Conversation",
            },
            {
                id: 2,
                title: "Monologue or Soliloquy",
            },
            {
                id: 3,
                title: "Emotional Expression (Crying, Laughing)",
            },
            {
                id: 4,
                title: "Physical Action or Movement",
            },
            {
                id: 5,
                title: "Dramatic Performance",
            },
            {
                id: 6,
                title: "Dancing or Choreography",
            },
            {
                id: 7,
                title: "Combat and Fight Sequences",
            },
            {
                id: 8,
                title: "Comedy and Physical Humor",
            },
            {
                id: 9,
                title: "Romance and Intimate Scenes",
            },
            {
                id: 10,
                title: "Surprise or Shock Reactions",
            },
            {
                id: 11,
                title: "Running or Chase Sequences",
            },
            {
                id: 12,
                title: "Emotional Breakdown",
            },
            {
                id: 13,
                title: "Cooking or Food Preparation",
            },
            {
                id: 14,
                title: "Playing Musical Instruments",
            },
            {
                id: 15,
                title: "Singing or Vocal Performance",
            },
            {
                id: 16,
                title: "Interactive Conversations (Phone Calls)",
            },
            {
                id: 17,
                title: "Demonstrations or Tutorials",
            },
            {
                id: 18,
                title: "Sport and Athletic Performances",
            },
            {
                id: 19,
                title: "Mimicry or Impersonations",
            },
            {
                id: 20,
                title: "Transformation or Disguise Acts",
            },
        ]
    )
    const handleActsOfActorClick = (title) => {
        setSelectedActsOfActor(title);
        handleInputChange({ target: { name: 'actsOfActor', value: title } });
    };

    ///Expected Innovative Technique    
    const [selectedInnovation, setSelectedInnovation] = useState(initialProject[0].innovation ? `${initialProject[0].innovation}` : "Click to Select One of the Items Listed");
    const [innovationList] = useState(
        [
            {
                id: 1,
                title: "Time-Remapping (Time-Warp Effect)",
            },
            {
                id: 2,
                title: "Hyperlapse with Motion Control",
            },
            {
                id: 3,
                title: "Data Moshing or Glitch Art",
            },
            {
                id: 4,
                title: "Procedural or Generative Art",
            },
            {
                id: 5,
                title: "Double Exposure Photography",
            },
            {
                id: 6,
                title: "Dolly Zoom (Vertigo Effect)",
            },
            {
                id: 7,
                title: "360-Degree Video or VR",
            },
            {
                id: 8,
                title: "Bullet Time (Matrix Effect)",
            },
            {
                id: 9,
                title: "Pixel Sorting",
            },
            {
                id: 10,
                title: "Long Exposure Light Painting",
            },
            {
                id: 11,
                title: "Augmented Reality (AR) Elements",
            },
            {
                id: 12,
                title: "Cinemagraphs (Still Images with Animated Elements)",
            },
            {
                id: 13,
                title: "Refractography (Crystal Ball Effect)",
            },
            {
                id: 14,
                title: "Kaleidoscope Effect",
            },
            {
                id: 15,
                title: "Prisma-Style Artistic Filters",
            },
            {
                id: 16,
                title: "Split Screen or Multiscreen",
            },
            {
                id: 17,
                title: "Anamorphic Lens Distortion",
            },
            {
                id: 18,
                title: "Forced Perspective Photography",
            },
            {
                id: 19,
                title: "Light Graffiti or Light Writing",
            },
            {
                id: 20,
                title: "Interactive or User-Generated Content",
            },
        ]
    )
    const handleInnovationClick = (title) => {
        setSelectedInnovation(title);
        handleInputChange({ target: { name: 'innovation', value: title } });
    };



    ///Add Storyboard    
    const [selectedStoryboard, setSelectedStoryboard] = useState(initialProject[0].storyboard ? `${initialProject[0].storyboard}` : "Click to Select One of the Items Listed");
    const [storyboardList] = useState(
        [
            {
                id: 1,
                title: "Detailed Shot-By-Shot Storyboard",
            },
            {
                id: 2,
                title: "Rough Sketch Storyboard",
            },
            {
                id: 3,
                title: "Visual References and Inspiration Boards",
            },
            {
                id: 4,
                title: "Animation or Motion Graphics Storyboard",
            },
            {
                id: 5,
                title: "Storyboard with Annotations or Notes",
            },
            {
                id: 6,
                title: "Interactive Digital Storyboard",
            },
            {
                id: 7,
                title: "Mobile App-Based Storyboard",
            },
            {
                id: 8,
                title: "Collaborative Storyboarding Tools",
            },
            {
                id: 9,
                title: "Mood Board or Visual Concept Board",
            },
            {
                id: 10,
                title: "Concept Art and Previsualization",
            },
            {
                id: 11,
                title: "Dynamic Animatics",
            },
            {
                id: 12,
                title: "Storyboard with Scene Descriptions",
            },
            {
                id: 13,
                title: "Web-Based Storyboarding Platforms",
            },
            {
                id: 14,
                title: "Traditional Hand-Drawn Storyboard",
            },
            {
                id: 15,
                title: "3D or Virtual Reality Storyboard",
            },
            {
                id: 16,
                title: "Video Storyboard with Timing",
            },
            {
                id: 17,
                title: "Experiential or Interactive Storyboard",
            },
            {
                id: 18,
                title: "Storyboard with Music and Sound Annotations",
            },
            {
                id: 19,
                title: "Multiple Alternative Storyboards",
            },
            {
                id: 20,
                title: "Interactive Storyboard with Hotspots",
            },
        ]
    )
    const handleStoryboardClick = (title) => {
        setSelectedStoryboard(title);
        handleInputChange({ target: { name: 'storyboard', value: title } });
    };


    ///Output Format 
    const [selectedOutputFormat, setSelectedOutputFormat] = useState(initialProject[0].output_format ? `${initialProject[0].output_format}` : "Click to Select One of the Items Listed");
    const [outPutFormatList] = useState(
        [
            {
                id: 1,
                title: "MP4",
            },
            {
                id: 2,
                title: "AVI",
            },
            {
                id: 3,
                title: "MOV",
            },
            {
                id: 4,
                title: "WMV",
            },
            {
                id: 5,
                title: "MKV",
            },
            {
                id: 6,
                title: "FLV",
            },
            {
                id: 7,
                title: "WebM",
            },
            {
                id: 8,
                title: "MPEG",
            },
            {
                id: 9,
                title: "MPEG-2",
            },
            {
                id: 10,
                title: "MPEG-4 Part-2",
            },
            {
                id: 11,
                title: "3GP",
            },
            {
                id: 12,
                title: "AVCHD",
            },
            {
                id: 13,
                title: "H.264",
            },
            {
                id: 14,
                title: "HEVC",
            },
            {
                id: 15,
                title: "MXF",
            },
        ]
    )
    const handleOutputFormatClick = (title) => {
        setSelectedOutputFormat(title);
        handleInputChange({ target: { name: 'output_format', value: title } });
    };

    ///Output Format 
    const [selectedFrameSize, setSelectedFrameSize] = useState(initialProject[0].fram_size ? `${initialProject[0].fram_size}` : "Click to Select One of the Items Listed");
    const [frameSizeList] = useState(
        [
            {
                id: 1,
                title: "640x480 (VGA)",
            },
            {
                id: 2,
                title: "720x480 (SD)",
            },
            {
                id: 3,
                title: "1280x720 (HD)",
            },
            {
                id: 4,
                title: "1920x1080 (Full HD)",
            },
            {
                id: 5,
                title: "2560x1440 (2K)",
            },
            {
                id: 6,
                title: "3840x2160 (4K UHD)",
            },
            {
                id: 7,
                title: "4096x2160 (DCI 4K)",
            },
            {
                id: 8,
                title: "7680x4320 (8K UHD)",
            },
            {
                id: 9,
                title: "320x240 (QVGA)",
            },
            {
                id: 10,
                title: "480x320 (HVGA)",
            },
            {
                id: 11,
                title: "854x480 (FWVGA)",
            },
            {
                id: 12,
                title: "1024x768 (XGA)",
            },
            {
                id: 13,
                title: "1366x768 (WXGA)",
            },
            {
                id: 14,
                title: "1600x900 (HD+)",
            },
            {
                id: 15,
                title: "2048x1080 (2K DCI)",
            },
        ]
    )
    const handleFrameSizeClick = (title) => {
        setSelectedFrameSize(title);
        handleInputChange({ target: { name: 'fram_size', value: title } });
    };



    /// Edit Request:

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleEditRequest = async () => {
        try {
            const response = await updateRequest(projectId, requestData);
            // Handle the response, e.g., show a success message, redirect, etc.
            console.log('requestData sent to back:', requestData);
            console.log('Edit successful', response);
            setRequestSuccess(true); // Set the request success state to true

            // Show success notification
            toast.success('Edit successful!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/dashboard-client' with the route you want to navigate to
                window.location.href = '/dashboard-client';
            }, 3000);
        } catch (error) {
            // Handle request error, e.g., display an error message
            console.error('Edit error', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;

                if (errorMessage === 'Invalid email format') {
                    toast.error('Please enter a valid email address!', {
                        // Toast configuration for invalid email format error
                    });
                }
            } else {
                toast.error('Edit failed!', {
                    // Toast configuration for other request errors
                });
            }
        }
    };

    const [title, setTitle] = useState(initialProject[0].title || '');
    const [fps, setFps] = useState(initialProject[0].fps || '');
    const [tags, setTags] = useState(initialProject[0].project_tags || '');
    const [description, setDescription] = useState(initialProject[0].project_description || '');
    const [budget, setBudget] = useState(initialProject[0].budget || '');



    const handleInputChange = (e) => {
        // Update the requestData state when input fields change
        setRequestData({ ...requestData, [e.target.name]: e.target.value });
        //console.log('InputChange:', requestData);
        if (e.target.name === 'title') {
            setTitle(e.target.value);
        }
        if (e.target.name === 'fps') {
            setFps(e.target.value);
        }
        if (e.target.name === 'project_description') {
            setDescription(e.target.value);
        }
        if (e.target.name === 'project_tags') {
            setTags(e.target.value);
        }
        if (e.target.name === 'budget') {
            setBudget(e.target.budget);
        }
    };



    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSettings = () => {
        setIsExpanded(!isExpanded);
    };

    const advButtonStyle = {
        padding: '10px 20px', // Adjust padding for desired size
        backgroundColor: 'rgb(241, 77, 128)', // Set background color using RGB values
        color: '#ffffff', // Set text color
        border: 'none', // Remove border
        borderRadius: '5px', // Add border radius for rounded corners
        cursor: 'pointer', // Change cursor to pointer on hover
    };

    if (!initialProject) {
        return <div>No project data available</div>;
    }

    return (
        <Fragment>
            <div className="flat-tabs tab-author">
                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}

                {
                    <div className="tf-section flat-explore flat-edit-profile flat-auctions-details ">
                        <div className="container">
                            <div className="form-upload-profile post">
                                <form action="#" className="form-profile" style={{ position: 'relative' }}>
                                    <div className="form-infor-profile">
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
                                            <h3 className="title-one fw-6">General Sepcs (Must be Filled)</h3>

                                            <div
                                                className="back-arrow"
                                                style={{ cursor: 'pointer', fontSize: '2em' }}
                                                onClick={() => navigate(-1)}
                                            >
                                                &#8592; Back
                                            </div>
                                        </div>
                                        <div className="form-infor flex flat-form"  >
                                            <div className="info-account" style={{ width: '30em' }}>
                                                <fieldset style={{ marginBottom: '1.5em', zIndex: 2 }}  >
                                                    <p className="title-infor-account">Collaboration Period</p>
                                                    <div id="item-create1" >
                                                        <Dropdown
                                                            style={{ zIndex: 2 }}
                                                        //className='dropdown-item' 
                                                        >
                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                style={{ minWidth: '21.5em', zIndex: 2 }}
                                                            //className='dropdown-item'
                                                            >
                                                                {selectedPeriod}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 2 }}
                                                            >
                                                                {periodList.map(data => (
                                                                    <Dropdown.Item
                                                                        style={{ width: '21.5em', zIndex: 2 }}
                                                                        key={data.id} onClick={() => handleItemClick(data.title)}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '0em', zIndex: 1 }}>
                                                    <p className="title-infor-account">Project Size</p>
                                                    <div   >
                                                        <Dropdown
                                                        >
                                                            <Dropdown.Toggle
                                                                style={{
                                                                    minWidth: '21.5em', zIndex: 1
                                                                }}
                                                            >
                                                                {selectedSize}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}
                                                            >
                                                                {sizeList.map(data => (
                                                                    <Dropdown.Item
                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                        key={data.id} onClick={() => handleSizeClick(data.title)}>{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <div>
                                                    </div>
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '2em', zIndex: 0 }}>
                                                    <p className="title-infor-account">Output Format</p>
                                                    <div   >
                                                        <Dropdown
                                                        >
                                                            <Dropdown.Toggle
                                                                style={{
                                                                    minWidth: '21.5em', zIndex: 0
                                                                }}
                                                            >
                                                                {selectedOutputFormat}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 0 }}
                                                            >
                                                                {outPutFormatList.map(data => (
                                                                    <Dropdown.Item
                                                                        style={{ width: '21.5em', zIndex: 0 }}
                                                                        key={data.id} onClick={() => handleOutputFormatClick(data.title)}>{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '2em' }}>
                                                    <p className="title-infor-account">Project Tags (At least 5 tags)</p>
                                                    <textarea tabIndex="4" rows="3" required name="project_tags"
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                        placeholder="Enter keywords or tags or skills related to your project."
                                                        value={tags}
                                                        style={{ zIndex: 0 }}
                                                    ></textarea>
                                                </fieldset>
                                            </div>
                                            <div className="info-social" style={{ width: '30em' }}>
                                                <fieldset style={{ marginBottom: '1em' }}>
                                                    <p className="title-infor-account">Project Title</p>
                                                    <input type="text" required
                                                        name="title"
                                                        value={title}
                                                        placeholder="Enter Your Project Title"
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                        style={{ zIndex: 0, maxHeight: "2.6em", marginBottom: "0.3em" }}
                                                    />
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '1em', zIndex: 1 }}>
                                                    <p className="title-infor-account">Required experience level</p>
                                                    <div id="item-create1" className="dropdown info-title" >
                                                        <Dropdown
                                                            style={{ marginBottom: '1em', zIndex: 1 }}>
                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                            >
                                                                {selectedExp}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                {expList.map(data => (
                                                                    <Dropdown.Item key={data.id} onClick={() => handleExpClick(data.title)}
                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <fieldset style={{ marginBottom: '1em', zIndex: 0, marginTop: "0.1em" }}>
                                                        <p className="title-infor-account">Number of frames per second</p>
                                                        <input type="number" 
                                                        placeholder= "30"
                                                        value = {fps}
                                                        name="fps" style={{ maxHeight: "2.6em" }} required
                                                            onChange={handleInputChange} // Call handleInputChange on input change
                                                        />
                                                    </fieldset>                                                                    </fieldset>
                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                    <p className="title-infor-account">Project Description</p>
                                                    <textarea tabIndex="4" rows="3" required name="project_description"
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                        placeholder="Describe your project"
                                                        value={description}
                                                    ></textarea>
                                                </fieldset>
                                            </div>
                                            <div className="info-social" style={{ width: '30em' }}>
                                                <fieldset style={{ marginBottom: '0.9em', zIndex: 1 }}>
                                                    <p className="title-infor-account">Project Category</p>
                                                    <div id="item-create1" className="dropdown info-title" >
                                                        <Dropdown style={{ zIndex: 1 }}>
                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                style={{ minWidth: '21.5em', zIndex: 1 }}
                                                            >
                                                                {selectedCategory}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                {categoryList.map(data => (
                                                                    <Dropdown.Item key={data.id} onClick={() => handleCategoryClick(data.title)}
                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '0.7em', marginTop: '0.5em' }}>
                                                    <p className="title-infor-account">Project Budget (EUR)</p>
                                                    <input type="number"
                                                        placeholder="Enter Your Project Budget in EUR"
                                                        value={budget}
                                                        name="budget"
                                                        required
                                                        style={{ maxHeight: "2.6em", marginBottom: "0.3em", marginTop: '0.3em' }}
                                                        onChange={handleInputChange} // Call handleInputChange on input change
                                                    />
                                                </fieldset>
                                                <fieldset style={{ marginBottom: '1em', zIndex: 0 }}>
                                                    <p className="title-infor-account">Frame Size</p>
                                                    <div id="item-create1" className="dropdown info-title" >
                                                        <Dropdown style={{ zIndex: 0 }}>
                                                            <Dropdown.Toggle id="dropdown-basic"
                                                                style={{ minWidth: '21.5em', zIndex: 0 }}
                                                            >
                                                                {selectedFrameSize}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 0 }}>
                                                                {frameSizeList.map(data => (
                                                                    <Dropdown.Item key={data.id} onClick={() => handleFrameSizeClick(data.title)}
                                                                        style={{ width: '21.5em', zIndex: 0 }}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <h3 className="title-one fw-6"> <br /> <br /> Advaced Settings</h3>
                                        <p className="title-one fw-6">Access advanced settings by clicking the button below to customize technical project requirements.
                                            <br />This section is optional but provides detailed customization options.
                                        </p>

                                        <button onClick={e => {
                                            e.preventDefault(); // Prevent the default form submission behavior
                                            toggleSettings();
                                        }}
                                            style={advButtonStyle}
                                        >
                                            {isExpanded ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
                                        </button>

                                        {isExpanded && (
                                            <div className="form-infor flex flat-form">
                                                <div className="info-account">
                                                    <fieldset style={{ zIndex: 7 }}>
                                                        <p className="title-infor-account">Shot Duration</p>
                                                        <Dropdown style={{ zIndex: 7 }}>
                                                            <Dropdown.Toggle id="dropdown-basic" style={{ width: '21.5em', zIndex: 7 }}>
                                                                {selectedShotDuration}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{ width: '21.5em', zIndex: 7 }}>
                                                                {shotDurationList.map(data => (
                                                                    <Dropdown.Item key={data.id} onClick={() => handleShotDurationClick(data.title)}
                                                                        style={{ width: '21.5em', zIndex: 7 }}
                                                                    >{data.title}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </fieldset>
                                                    <fieldset style={{ zIndex: 6 }}>
                                                        <p className="title-infor-account">Image Depth Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 6 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ minWidth: '21.5em', zIndex: 6 }}
                                                                >
                                                                    {selectedImageDepth}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 6 }}>
                                                                    {imageDepthList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleImageDepthClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 6 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                </fieldset>
                                                    <fieldset style={{ zIndex: 5 }}>
                                                        <p className="title-infor-account">Output Image Quality</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 5 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 5 }}
                                                                >
                                                                    {selectedQuality}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 5 }}>
                                                                    {qualityList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleQualityClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 5 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 4 }}>
                                                        <p className="title-infor-account">State of Pan Movement Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 4 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 4 }}
                                                                >
                                                                    {selectedPanMovement}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 4 }}>
                                                                    {panMovementList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handlePanMovementClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 4 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 3 }}>
                                                        <p className="title-infor-account">State of Dolly Movement Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 3 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 3 }}
                                                                >
                                                                    {selectedDollyMovement}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 3 }}>
                                                                    {dollyMovementList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleDollyMovementClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 3 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 2 }}>
                                                        <p className="title-infor-account">State of Tilt Movement Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 2 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 2 }}
                                                                >
                                                                    {selectedTiltMovement}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 2 }}>
                                                                    {tiltMovementList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleTiltMovementClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 2 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 1 }}>
                                                        <p className="title-infor-account">State of Crane Movement Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 1 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 1 }}
                                                                >
                                                                    {selectedCraneMovement}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                    {craneMovementList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleCraneMovementClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 1 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>


                                                </div>
                                                <div className="info-social">
                                                    <fieldset style={{ zIndex: 7 }}>
                                                        <p className="title-infor-account">Light Conditions</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 7 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 7 }}
                                                                >
                                                                    {selectedLight}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 7 }}>
                                                                    {lightList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleLightClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 7 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 6 }}>
                                                        <p className="title-infor-account">Recommended Camera</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 6 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 6 }}
                                                                >
                                                                    {selectedCamera}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 6 }}>
                                                                    {cameraList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleCameraClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 6 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 5 }}>
                                                        <p className="title-infor-account">Status of Dominant Colors</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 5 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 5 }}
                                                                >
                                                                    {selectedColor}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 5 }}>
                                                                    {colorList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleColorClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 5 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 4 }}>
                                                        <p className="title-infor-account">State of Inferior Colors</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 4 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 4 }}
                                                                >
                                                                    {selectedInferiorColor}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 4 }}>
                                                                    {inferiorcolorList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleInferiorColorClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 4 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 3 }}>
                                                        <p className="title-infor-account">State of Objects in the View</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 3 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 3 }}
                                                                >
                                                                    {selectedObjectsInView}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 3 }}>
                                                                    {objectsInViewList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleObjectsInViewClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 3 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 2 }}>
                                                        <p className="title-infor-account">Description of Each Object in the View</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 2 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 2 }}
                                                                >
                                                                    {selectedDescriptionOfObject}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 2 }}>
                                                                    {descriptionOfObjectList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleDescriptionOfObjectClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 2 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset style={{ zIndex: 1 }}>
                                                        <p className="title-infor-account">Ratio of the Part to the Whole</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 1 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 1 }}
                                                                >
                                                                    {selectedObjectRatio}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                    {objectRatioList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleObjectRatioClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 1 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>


                                                </div>
                                                <div className="info-social">
                                                    <fieldset style={{ zIndex: 7 }}>
                                                        <p className="title-infor-account">Exposure Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 7 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 7 }}
                                                                >
                                                                    {selectedExposure}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 7 }}>
                                                                    {exposureList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleExposureClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 7 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 6 }}>
                                                        <p className="title-infor-account">Recommended Lens</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 6 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 6 }}
                                                                >
                                                                    {selectedLens}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 6 }}>
                                                                    {lensList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleLensClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 6 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>

                                                    <fieldset style={{ zIndex: 5 }}>
                                                        <p className="title-infor-account">State of Aerial Shot Movement Status</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 5 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 5 }}
                                                                >
                                                                    {selectedAerialShot}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 5 }}>
                                                                    {aerialShotList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleAerialShotClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 5 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 4 }}>
                                                        <p className="title-infor-account">Number of Possible Actors</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 4 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 4 }}
                                                                >
                                                                    {selectedNumberOfActors}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 4 }}>
                                                                    {numberOfActorsList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleNumberOfActorsClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 4 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>                                                                      </fieldset>
                                                    <fieldset style={{ zIndex: 3 }}>
                                                        <p className="title-infor-account">Acts of Each Actor</p>
                                                        <div id="item-create1" className="dropdown info-title" >
                                                            <Dropdown style={{ zIndex: 3 }}>
                                                                <Dropdown.Toggle id="dropdown-basic"
                                                                    style={{ width: '21.5em', zIndex: 3 }}
                                                                >
                                                                    {selectedActsOfActor}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ width: '21.5em', zIndex: 3 }}>
                                                                    {actsOfActorList.map(data => (
                                                                        <Dropdown.Item key={data.id} onClick={() => handleActsOfActorClick(data.title)}
                                                                            style={{ width: '21.5em', zIndex: 3 }}
                                                                        >{data.title}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <fieldset style={{ zIndex: 2 }}>
                                                            <p className="title-infor-account">Expected Innovative Technique</p>
                                                            <div id="item-create1" className="dropdown info-title" >
                                                                <Dropdown style={{ zIndex: 2 }}>
                                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                        style={{ width: '21.5em', zIndex: 2 }}
                                                                    >
                                                                        {selectedInnovation}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu style={{ width: '21.5em', zIndex: 2 }}>
                                                                        {innovationList.map(data => (
                                                                            <Dropdown.Item key={data.id} onClick={() => handleInnovationClick(data.title)}
                                                                                style={{ width: '21.5em', zIndex: 2 }}
                                                                            >{data.title}</Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>                                                                      </fieldset>

                                                        <fieldset style={{ zIndex: 1 }}>
                                                            <p className="title-infor-account">Type of Required Storyboard</p>
                                                            <div id="item-create1" className="dropdown info-title" >
                                                                <Dropdown style={{ zIndex: 1 }}>
                                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                        style={{ width: '21.5em', zIndex: 1 }}
                                                                    >
                                                                        {selectedStoryboard}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu style={{ width: '21.5em', zIndex: 1 }}>
                                                                        {storyboardList.map(data => (
                                                                            <Dropdown.Item key={data.id} onClick={() => handleStoryboardClick(data.title)}
                                                                                style={{ width: '21.5em', zIndex: 1 }}
                                                                            >{data.title}</Dropdown.Item>
                                                                        ))}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>                                                                      </fieldset>                                                              </fieldset>
                                                </div>
                                            </div>
                                        )}
                                        <h3 className="title-two fw-6"><br />Required Coins for Submit:</h3>
                                        <div className="form-infor flex flat-form">
                                            <div className="info-account">
                                                <fieldset style={{ marginBottom: '1em' }}>
                                                    <p className="title-infor-account">0 coins</p>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="tf-button-submit mg-t-15" style={{ zIndex: 0 }}
                                        onClick={e => {
                                            e.preventDefault(); // Prevent the default form submission behavior
                                            handleEditRequest();
                                        }}>
                                        Submit Your Edits
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                }

            </div >
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment >
    );
};


export default Profile1;
