import img1 from '../images/box-item/cetagories-1.png';
import img2 from '../images/box-item/cetagories-2.png';
import img3 from '../images/box-item/cetagories-3.png';
import img4 from '../images/box-item/cetagories-4.png';
import img5 from '../images/box-item/cetagories-5.png';
import img6 from '../images/box-item/cetagories-6.png';
import img7 from '../images/box-item/cetagories-7.png';
import img8 from '../images/box-item/cetagories-8.png';
import img9 from '../images/box-item/cetagories-9.png';

import img10 from '../images/box-item/cetagories-10.png';
import img11 from '../images/box-item/cetagories-11.png';
import img12 from '../images/box-item/cetagories-12.jpg';
import img13 from '../images/box-item/cetagories-13.jpg';
import img14 from '../images/box-item/cetagories-14.jpg';
import img15 from '../images/box-item/cetagories-15.jpg';

const dataBanner = [
    {
        id: 1,
        title: 'Cameraman',
        desc: '50+ Specialists',
        img: img1,
    },
    {
        id: 2,
        title: 'Documentary filmmaker',
        desc: '10+ Specialists',
        img: img2,
    },
    {
        id: 3,
        title: 'Advertising filmmaker',
        desc: '50+ Specialists',
        img: img3,
    },
    {
        id: 4,
        title: 'Editor and composer',
        desc: '50+ Specialists',
        img: img4,
    },
    {
        id: 5,
        title: 'Lighting specialist',
        desc: '20+ Specialists',
        img: img5,
    },
    {
        id: 6,
        title: '3D modeler',
        desc: '30+ Specialists',
        img: img6,
    },
    {
        id: 7,
        title: 'Rendering specialist',
        desc: '10+ Specialists',
        img: img7,
    },
    {
        id: 8,
        title: 'Director',
        desc: '30+ Specialists',
        img: img8,
    },
    {
        id: 9,
        title: 'Screenwriter',
        desc: '20+ Specialists',
        img: img9,
    },

    {
        id: 10,
        title: 'Computer Graphics (CG)',
        desc: '20+ Specialists',
        img: img10,
    },
    {
        id: 11,
        title: 'Photographer',
        desc: '40+ Specialists',
        img: img11,
    },
]

export default dataBanner;